import Guid from "devextreme/core/guid";
import notify from "devextreme/ui/notify";
import React from "react";
import { useHistory } from "react-router-dom";

import translate from "../../i18n/i10n/translate";
import useCurrentUser, { DEFAULT_IDENTITY_CUSTOMER_ID } from "../../user/useCurrentUser";

import UserEdit from "./edit/UserEdit";
import { defaultInsertUser } from "./insert/defaultInsertUser";
import insertUser from "./insert/insertUser";
import IInsertUser from "./models/IInsertUser";
import { UserListPath } from "./UserList";

export const UserInvitePath = "/iframe/users/invite";

const UserInvite = () => {
    const history = useHistory();
    const currentUser = useCurrentUser();
    const [isReady] = React.useState(true);
    const [isValid] = React.useState(true);
    const [tenantId] = React.useState<Guid>(new Guid(currentUser.profile.epiTenantId));
    const [groupId] = React.useState(DEFAULT_IDENTITY_CUSTOMER_ID);
    const [user, setUser] = React.useState<IInsertUser>({
        ...defaultInsertUser,
    });

    // On Submit, insert User
    const handleSubmit = async (selectedRoleIds: string[]) => {
        const notifyOfError = () =>
            notify(
                translate("Failed to invite user, please check details and try again."),
                "error",
            );

        try {
            const response = await insertUser(user, tenantId.toString(), groupId, selectedRoleIds);

            if (!response.successful) {
                notifyOfError();

                return;
            }
            history.push(UserListPath);
        } catch (ex) {
            notifyOfError();
        }
    };

    const handleUserChanged = (user: IInsertUser) => {
        setUser(user);
    };

    return (
        <div>
            <UserEdit
                isReady={isReady}
                isValid={isValid}
                isWorking={false}
                submitActionText={translate("Invite")}
                tenantId={tenantId}
                user={user}
                onSubmit={handleSubmit}
                onUserChanged={handleUserChanged}
            />
        </div>
    );
};

export default UserInvite;
