import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item, Tab} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext, {getTenantsDataSource} from "../../odata/accountServiceContext";
import TenantApplicationRoleContextValuesTemplate from "./TenantApplicationRoleContextValuesTemplate";
import TenantContext from "./TenantContext";

export default class TenantApplicationRoleContextsTemplate extends React.Component {
    static contextType = TenantContext;

    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.roleContextsDataSource = getRoleContextsDataSource(props.data.key, props.data.data.tenantApplication.id);
            this.contextsDataSource = getContextsDataSource(props.data.data.tenantApplication.id);
            this.tenantsDataSource = getTenantsDataSource();
        }
    }

    componentDidMount() {
        this.tenantId = this.context.tenantId;
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.tenantId = this.tenantId;
        e.data.roleId = this.props.data.key;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.roleContextsDataSource}
                popupTitle="Role Contexts Info"
                onInitNewRow={this.onInitNewRow}>

                {/* Grid Columns */}
                <Column dataField="tenantId" dataType="Guid" caption="Tenant" allowEditing={false} visible={false}>
                    <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="contextId" dataType="Guid" caption="Context">
                    <Lookup dataSource={this.contextsDataSource} displayExpr="name" valueExpr="id"/>
                </Column>
                <Column dataField="contextByUserRole" caption="Context By User Role" dataType="boolean"/>
                <Column dataField="context.name" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}/>
                <Column dataField="roleContextValues" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}
                        editCellComponent={TenantApplicationRoleContextValuesTemplate}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId", "contextId", "contextByUserRole"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2}>
                    <Tab title="Context Values" items={[{dataField: "roleContextValues", label: {visible: false}}]}/>
                    <Tab title="Audit Info" colCount={2} items={["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}/>
                </Item>

            </BaseDataGrid>
        );
    }
}

function getRoleContextsDataSource(roleId, tenantApplicationId) {
    return {
        store: accountServiceContext.roleContexts,
        filter: [['roleId', '=', roleId], "and", ["context.tenantApplicationId", "=", tenantApplicationId]],
        expand: "role,context",
        sort: 'context.name'
    };
}

function getContextsDataSource(tenantApplicationId) {
    return {
        store: accountServiceContext.contexts,
        select: ['id', 'name'],
        filter: ['tenantApplicationId', '=', tenantApplicationId],
        sort: 'name'
    };
}

