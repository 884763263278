import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext from "../../odata/accountServiceContext";

export default class ApplicationEnvironmentsTemplate extends React.Component {
    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.dataSource = getApplicationEnvironments(props.data.key);
        }
    }

    componentDidMount() {
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.applicationId = this.props.data.key;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.dataSource}
                popupTitle="Application Environment Info"
                onInitNewRow={this.onInitNewRow}>

                {/* Grid Columns */}
                <Column dataField="environmentId" dataType="Guid" caption="Environment">
                    <Lookup dataSource={{store: accountServiceContext.environments, select: ['id', 'name'], sort: 'name'}} displayExpr="name" valueExpr="id"/>
                </Column>
                <Column dataField="environment.name" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={1} colSpan={2} items={["id", "environmentId"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2} tabs={[{title: "Audit Info", colCount: 2, items: ["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}]}/>

            </BaseDataGrid>
        );
    }
}

function getApplicationEnvironments(applicationId) {
    return {
        store: accountServiceContext.applicationEnvironments,
        filter: ['applicationId', '=', applicationId],
        sort: 'environment.name'
    };
}

