import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item, Tab} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext from "../../odata/accountServiceContext";
import UserRoleContextValuesTemplate from "./UserRoleContextValuesTemplate";

export default class UserRolesTemplate extends React.Component {
    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.isSystemUser = props.data.data.tenantId.toString() === "00000000-0000-0000-0000-000000000000";
            this.userRolesDataSource = getUserRoleDataSource(props.data.key);
            this.rolesDataSource = getRolesDataSource();
            this.environmentsDataSource = getEnvironmentsDataSource();
            this.tenantsDataSource = getUserTenantsDataSource(props.data.key, this.isSystemUser);
        }
    }

    componentDidMount() {
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.tenantId = this.props.data.data.tenantId;
        e.data.userId = this.props.data.key;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.userRolesDataSource}
                popupTile="User Role Info"
                onInitNewRow={this.onInitNewRow}>

                {/* Grid Columns */}
                <Column dataField="tenantId" dataType="Guid" caption="Tenant" allowEditing={this.isSystemUser}>
                    <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="roleId" dataType="Guid" caption="Role">
                    <Lookup dataSource={this.rolesDataSource} displayExpr="applicationRole" valueExpr="id"/>
                </Column>
                <Column dataField="environmentId" dataType="Guid" caption="Environment">
                    <Lookup dataSource={this.environmentsDataSource} displayExpr="name" valueExpr="id"/>
                </Column>
                <Column dataField="userRoleContextValues" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false} editCellComponent={UserRoleContextValuesTemplate}/>
                <Column dataField="role.applicationRole" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId"]}/>
                <Item itemType="group" colCount={2} colSpan={2} items={["roleId", "environmentId"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2}>
                    <Tab title="Context Values" items={[{dataField: "userRoleContextValues", label: {visible: false}}]}/>
                    <Tab title="Audit Info" colCount={2} items={["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}/>
                </Item>

            </BaseDataGrid>
        );
    }
}

function getUserRoleDataSource(userId) {
    return {
        store: accountServiceContext.userRoles,
        filter: ['userId', '=', userId],
        sort: "role.applicationRole"
    };
}

function getRolesDataSource() {
    return {
        store: accountServiceContext.roles,
        select: ['id', 'applicationRole'],
        sort: 'applicationRole'
    };
}

function getEnvironmentsDataSource() {
    return {
        store: accountServiceContext.environments,
        select: ['id', 'name'],
        sort: 'name'
    };
}

function getUserTenantsDataSource(userId, isSystemUser) {
    const filter = isSystemUser
        ? [
            ["id", "=", "00000000-0000-0000-0000-000000000000"],
            "or",
            [`usertenants/any(o:o/userid eq ${userId})`]
        ]
        : null;

    return {
        store: accountServiceContext.tenants,
        select: ["id", "companyName"],
        filter,
        sort: "companyName",
    };
}
