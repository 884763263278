import React from "react";
import DXDataGrid, { Pager, Paging, IDataGridOptions } from "devextreme-react/ui/data-grid";
import "./DataGrid.tsx.scss";

type Props = IDataGridOptions;

const DataGrid: React.FC<Props> = ({ children, ...otherProps }) => {
    return (
        <div className="data-grid-control">
            <DXDataGrid
                allowColumnReordering={false}
                allowColumnResizing={false}
                columnAutoWidth={false}
                columnMinWidth={50}
                showBorders={false}
                showColumnLines={false}
                showRowLines={false}
                {...otherProps}
            >
                <Paging defaultPageSize={10} />
                <Pager allowedPageSizes={[5, 10, 20]} showInfo showPageSizeSelector visible />
                {children}
            </DXDataGrid>
        </div>
    );
};

export default DataGrid;
