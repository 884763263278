import IInsertUser from "./IInsertUser";
import IUpdateUser from "./IUpdateUser";

type IEditUser = IInsertUser | IUpdateUser;

export const isUpdateUser = (user: IEditUser): user is IUpdateUser => "id" in user;

export const isInsertUser = (user: IEditUser): user is IUpdateUser => !isUpdateUser(user);

export default IEditUser;
