import Guid from "devextreme/core/guid";

import getUserRolesChangedDetail from "../roles/getUserRolesChangedDetail";
import IUser from "../../../odata/entities/IUser";
import accountServiceContext from "../../../odata/accountServiceContext";
import IUpdateUser from "../models/IUpdateUser";

const toUpdateUser = (user: IUpdateUser): IUser => ({
    id: user.id,
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
});

const updateUser = async (user: IUpdateUser, roleIds: string[]) => {
    // Remove/Insert Changed User Roles
    const userRolesChangedDetail = getUserRolesChangedDetail(user.userRoles || [], roleIds);

    if (userRolesChangedDetail.length > 0) {
        for (const userRole of userRolesChangedDetail) {
            if ("id" in userRole) {
                await accountServiceContext.userRoles.remove(userRole.id);
            } else {
                await accountServiceContext.userRoles.insert({
                    tenantId: user.tenantId as Guid,
                    userId: user.id,
                    roleId: userRole.roleId,
                });
            }
        }
    }
    // Update User Details
    await accountServiceContext.users.update(user.id, toUpdateUser(user));

    return { successful: true };
};

export default updateUser;
