import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item, Tab} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext, {getTenantsDataSource} from "../../odata/accountServiceContext";
import TenantApplicationRolePermissionsTemplate from "./TenantApplicationRolePermissionsTemplate";
import TenantApplicationRoleContextsTemplate from "./TenantApplicationRoleContextsTemplate";
import Guid from "devextreme/core/guid";
import TenantContext from "./TenantContext";

export default class TenantApplicationRolesTemplate extends React.Component {
    static contextType = TenantContext;
    
    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.rolesDataSource = getRolesDataSource(props.data.data);
            this.tenantsDataSource = getTenantsDataSource();
        }
    }

    componentDidMount() {
        this.tenantId = this.context.tenantId;
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.tenantId = this.tenantId;
        e.data.tenantApplicationId = this.props.data.key;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.rolesDataSource}
                popupTitle="Role Info"
                onInitNewRow={this.onInitNewRow}>

                {/* Grid Columns */}
                <Column dataField="tenantId" dataType="Guid" caption="Tenant" allowEditing={false} visible={false}>
                    <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="applicationRole"/>
                <Column dataField="name"/>
                <Column dataField="description"/>
                <Column dataField="isSystemRole" dataType="boolean"/>
                <Column dataField="isImplementerRole" dataType="boolean"/>
                <Column dataField="ignoreContext" dataType="boolean" visible={false}/>
                <Column dataField="rolePermissions" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false} editCellComponent={TenantApplicationRolePermissionsTemplate}/>
                <Column dataField="roleContexts" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false} editCellComponent={TenantApplicationRoleContextsTemplate}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId", "name", "description", "isSystemRole", "isImplementerRole", "ignoreContext", "applicationRole"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2}>
                    <Tab title="Permissions" items={[{dataField: "rolePermissions", label: {visible: false}}]}/>
                    <Tab title="Contexts" items={[{dataField: "roleContexts", label: {visible: false}}]}/>
                    <Tab title="Audit Info" colCount={2} items={["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}/>
                </Item>

            </BaseDataGrid>
        );
    }
}

function getRolesDataSource(tenantApplication) {
    return {
        store: accountServiceContext.roles,
        filter: [
            ['tenantApplicationId', '=', tenantApplication.id],
            "or",
            [
                ["tenantApplication.tenantId", "=", new Guid("00000000-0000-0000-0000-000000000000")],
                "and",
                ["tenantApplication.applicationId", "=", tenantApplication.applicationId]
            ]
        ],
        sort: 'applicationRole',
        postProcess: (data) => {
            if (data && data.length > 0) {
                data.forEach(row => row.tenantApplication = tenantApplication);
            }

            return data;
        }
    };
}

