import React from "react";
import LoadIndicator from "devextreme-react/ui/load-indicator";

import uuid from "../../core/UUID";
import "./Button.tsx.scss";

interface Props
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    id?: string;
    className?: string;
    displayedAs?: "link" | "button";
    styledAs?: "primary" | "default";
    inProgress?: boolean;
}

const Button: React.FC<Props> = ({
    id,
    displayedAs = "button",
    className = "",
    styledAs = "",
    inProgress = false,
    children,
    ...otherProps
}) => {
    const [buttonId] = React.useState(id || uuid());

    return (
        // If className has any more additions, look at using an external manager.
        <button className={`${displayedAs} ${className} ${styledAs}`} id={buttonId} {...otherProps}>
            <LoadIndicator className="in-progress-indicator" visible={inProgress} />
            {children}
        </button>
    );
};

export default Button;
