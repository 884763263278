import DataGrid, { Column, Pager, Paging, StateStoring } from "devextreme-react/ui/data-grid";
import { TextBox } from "devextreme-react/ui/text-box";
import { DataSourceOptions } from "devextreme/data/data_source";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import accountServiceContext from "../../odata/accountServiceContext";
import Button from "../controls/Button";
import translate from "../../i18n/i10n/translate";
import useUserSettings, { SearchSettings, setUserSearchSettings } from "../../user/useUserSettings";

import { UserInvitePath } from "./UserInvite";

import "./UserList.tsx.scss";

export const UserListPath = "/iframe/users";
const userSettingsKey = "userList";

const EditButtonCell = ({ data }: { data: { key: string; value: string } }) => {
    const history = useHistory();
    const handleEditButtonClicked = () => {
        history.push(`${UserListPath}/${data.key}/edit`);
    };

    return (
        <div className="epi-link" onClick={handleEditButtonClicked}>
            {data.value}
        </div>
    );
};

const UserListFC = () => {
    const history = useHistory();
    const userSettings = useUserSettings();
    const [searchSettings, setSearchSettings] = useState<SearchSettings | undefined>(
        userSettings.search[userSettingsKey],
    );
    const [dataSourceOptions, setDataSourceOptions] = useState<DataSourceOptions>({
        store: accountServiceContext.users,
        sort: "username",
    });

    useEffect(() => {
        if (searchSettings?.filter) {
            setDataSourceOptions({
                ...dataSourceOptions,
                filter: [
                    ["username", "contains", searchSettings.filter],
                    "or",
                    ["firstName", "contains", searchSettings.filter],
                    "or",
                    ["lastName", "contains", searchSettings.filter],
                ],
            });
        }
    }, []);

    const handleSearchChange = (data: Partial<{ value: string }>) => {
        const newSearchSettings = {
            ...searchSettings,
            filter: data.value,
        };
        setSearchSettings(newSearchSettings);
        setUserSearchSettings(userSettingsKey, newSearchSettings);
        // Add a filter to the dataSourceOptions that will find records
        // that contain the search text.
        setDataSourceOptions({
            ...dataSourceOptions,
            filter: [
                ["username", "contains", data.value],
                "or",
                ["firstName", "contains", data.value],
                "or",
                ["lastName", "contains", data.value],
            ],
        });
    };

    const handleInviteButtonClicked = () => {
        history.push(UserInvitePath);
    };

    const handleStateStoringLoad = () =>
        new Promise((resolve) => {
            resolve(searchSettings?.gridState);
        });

    const handleStateStoringSave = (gridState: unknown) => {
        const newSearchSettings = {
            ...searchSettings,
            gridState,
        };
        setSearchSettings(newSearchSettings);
        setUserSearchSettings(userSettingsKey, newSearchSettings);
    };

    return (
        <div className="user-list">
            <div className="user-list__header">
                <h1 className="user-list__title" data-test-selector="page-title">
                    {translate("Users")}
                </h1>
                <Button
                    className="user-list__invite-button"
                    styledAs="primary"
                    onClick={handleInviteButtonClicked}
                >
                    {translate("Invite")}
                </Button>
            </div>
            <div className="user-list__search-bar">
                <TextBox
                    className="user-list__search-bar__text-box"
                    placeholder={translate("Search by name or email")}
                    value={searchSettings?.filter}
                    onValueChanged={handleSearchChange}
                />
            </div>
            <div className="user-list__data-grid">
                <DataGrid
                    columnMinWidth={50}
                    columnResizingMode="widget"
                    dataSource={dataSourceOptions}
                    showBorders={false}
                    showColumnLines={false}
                    showRowLines={false}
                    allowColumnReordering
                    allowColumnResizing
                    columnAutoWidth
                >
                    <Paging defaultPageSize={25} />
                    <Pager allowedPageSizes={[25, 50, 100]} showInfo showPageSizeSelector visible />
                    <StateStoring
                        customLoad={handleStateStoringLoad}
                        customSave={handleStateStoringSave}
                        savingTimeout={200}
                        storageKey="UserList"
                        type="custom"
                        enabled
                    />

                    <Column
                        caption={translate("Email")}
                        cellComponent={EditButtonCell}
                        dataField="username"
                    />
                    <Column caption={translate("First Name")} dataField="firstName" />
                    <Column caption={translate("Last Name")} dataField="lastName" />
                    <Column
                        caption={translate("Last Logged In")}
                        dataField="lastLoggedIn"
                        dataType="datetime"
                    />
                </DataGrid>
            </div>
        </div>
    );
};

export default UserListFC;
