import { Template } from "devextreme-react/core/template";
import { Column, Selection, TreeList } from "devextreme-react/ui/tree-list";
import Guid from "devextreme/core/guid";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import Popup from "devextreme-react/popup";

import translate from "../../../i18n/i10n/translate";
import { Popover } from "devextreme-react/popover";
import accountServiceContext, { DEFAULT_SYSTEM_GUID } from "../../../odata/accountServiceContext";
import ITenantApplication from "../../../odata/entities/ITenantApplication";
import Button from "../../controls/Button";
import TextBox from "../../controls/TextBox";
import UserEditApplicationContexts, {
    IUserRoleContextValueChange,
} from "../contexts/UserEditApplicationContexts";
import IEditUser, { isUpdateUser } from "../models/IEditUser";
import IUpdateUser from "../models/IUpdateUser";
import { UserListPath } from "../UserList";
import isEmailValid from "../validation/isValidEmail";
import userRolesChanged from "../validation/userRolesChanged";
import IRole from "../../../odata/entities/IRole";
import IUserRoleContextValue, { NEW_GUID_ID } from "../../../odata/entities/IUserRoleContextValue";
import PartialBy from "../../../core/PartialBy";
import { ReactComponent as TooltipIcon } from "./tooltip.svg";

import "./UserEdit.tsx.scss";
import useCurrentUser from "../../../user/useCurrentUser";

type IApplicationRole = {
    id: string;
    description: string;

    type: "role" | "application";
    // Set this to the Role's ApplicationId
    applicationId?: string;
    // Roles used for tooltips
    applicationRole?: string;
};

const getApplication = (applicationList: ITenantApplication[], applicationId: Guid) => {
    return applicationList.filter((a) => a.applicationId.toString() === applicationId.toString());
};

const isApplication = (applicationList: ITenantApplication[], roleId: string) => {
    return getApplication(applicationList, roleId).length > 0;
};

const getApplicationRoles = (
    applicationList: ITenantApplication[],
    applicationId: string,
): IRole[] => {
    return getApplication(applicationList, applicationId)[0]?.roles || [];
};

const ROLE_ORDER = [
    "InsitePIM",
    "Administrator",
    "Manager",
    "Merchandiser",
    "Product Importer",
    "Asset Manager",
];

const roleSortHandler = (a: IApplicationRole, b: IApplicationRole) => {
    let aIndex = ROLE_ORDER.indexOf(a.description);
    let bIndex = ROLE_ORDER.indexOf(b.description);

    if (aIndex === -1) {
        aIndex = ROLE_ORDER.length;
    } else if (bIndex === -1) {
        bIndex = ROLE_ORDER.length;
    }

    if (aIndex > bIndex) {
        return 1;
    } else if (bIndex > aIndex) {
        return -1;
    } else {
        return 0;
    }
};

const ROLE_TEXT: { [index: string]: string } = {
    PIM_Manager:
        "Gives permission to delete or approve assigned products and to create or edit properties and product templates.",
    PIM_Admin: "Gives full access to all areas of PIM.",
    PIM_Merchandiser: "Gives permission to edit assigned products.",
    PIM_ProductImporter:
        "Gives users with manager or merchandiser access additional permission to import products.",
    PIM_AssetManager:
        "Gives users with manager or merchandiser access additional permission to the assets section of PIM.",
};

type Props = {
    isReady: boolean;
    isWorking: boolean;
    isValid: boolean;
    tenantId?: Guid;
    user: IEditUser;
    userActions?: React.ReactNode;
    detailsDescription?: React.ReactNode;
    moreFormElements?: React.ReactNode;
    submitActionText: string;
    onUserChanged: (newUser: IEditUser) => void;
    onSubmit: (roleIds: string[]) => Promise<void>;
    onReset?: () => Promise<void>;
};

const UserEdit: React.FC<Props> = ({
    isReady,
    isWorking,
    isValid,
    user,
    tenantId,
    userActions,
    detailsDescription,
    moreFormElements,
    submitActionText,
    onUserChanged,
    onSubmit,
    onReset,
}) => {
    const history = useHistory();
    const currentUser = useCurrentUser();

    const [isLoading, setIsLoading] = React.useState(true);
    const [pendingChanges, setPendingChanges] = React.useState(false);
    const [isCoreFieldsValid, setIsCoreFieldsValid] = React.useState(true);
    const [inProgress, setInProgress] = React.useState(false);
    const [tenantApplications, setTenantApplications] = React.useState<ITenantApplication[]>([]);
    const [tenantApplicationContexts, setTenantApplicationContexts] = React.useState<
        ITenantApplication[]
    >([]);
    const [applicationRoles, setApplicationRoles] = React.useState<IApplicationRole[]>([]);
    const [selectedRoleIds, setSelectedRoleIds] = React.useState<string[]>([]);
    const [hasIgnoreContextRole, setHasIgnoreContextRole] = React.useState<boolean>(false);
    const [usernameErrorMessage, setUsernameErrorMessage] = React.useState<string | undefined>();
    const [selectedRoleError, setSelectedRoleError] = React.useState<string | undefined>();
    const [activeTabName, setActiveTabName] = React.useState("details-tab");
    const [popoverId, setPopoverId] = React.useState("");
    const [expandedRoleId, setExpandedRoleId] = React.useState<string[]>([]);

    const [isPendingChangesPopupOpen, setIsPendingChangesPopupOpen] = React.useState(false);

    const [pendingUserRoleContextValues, setPendingUserRoleContextValues] = React.useState<
        IUserRoleContextValueChange[]
    >([]);

    // We need to track mounted state, incase the page redirects on submit.
    const componentIsMounted = React.useRef(true);
    React.useEffect(() => {
        return () => {
            componentIsMounted.current = false;
        };
    }, []);

    // Load Tenant Application/Roles
    React.useEffect(() => {
        if (!tenantId) {
            return;
        }
        // Load User
        accountServiceContext.tenantApplications
            .load({
                expand: ["application", "roles"],
                filter: [["tenantId", "=", DEFAULT_SYSTEM_GUID], "or", ["tenantId", "=", tenantId]],
            })
            .then((tenantApplications) => {
                const currentUserRoles = currentUser.profile.roles;
                const adminRoles = currentUserRoles.filter((roleString) =>
                    roleString.match(/admin/i),
                );
                const filteredTenantApplications = tenantApplications.filter((tenantApp) => {
                    if (currentUserRoles.includes("SYS_System")) {
                        return true;
                    } else {
                        return adminRoles.some((adminRole) =>
                            adminRole.match(tenantApp.application.name),
                        );
                    }
                });
                setTenantApplications(filteredTenantApplications);
            });
    }, [tenantId]);

    // Load Tenant Applications with Contexts
    React.useEffect(() => {
        if (!tenantId || selectedRoleIds.length === 0) {
            return;
        }
        // Load User
        accountServiceContext.contexts
            .load({
                select: "tenantApplication",
                expand: ["tenantApplication", "tenantApplication.application"],
                filter: [
                    [["tenantId", "=", DEFAULT_SYSTEM_GUID], "or", ["tenantId", "=", tenantId]],
                ],
            })
            .then((tenantApplications) => {
                setTenantApplicationContexts(
                    tenantApplications
                        .map((a) => a.tenantApplication)
                        // Filter out duplicate tenantApplications by ApplicationId
                        .filter((tenantApplication, index, array) => {
                            return (
                                array
                                    .map((mapObj) => mapObj.applicationId.toString())
                                    .indexOf(tenantApplication.applicationId.toString()) === index
                            );
                        }),
                );
            });
    }, [tenantId, selectedRoleIds, hasIgnoreContextRole]);

    React.useEffect(() => {
        if (tenantApplications.length === 0 || selectedRoleIds.length > 0) {
            return;
        }

        const applicationRoles: IApplicationRole[] = [];
        const newSelectedRoleIds: string[] = [];
        let userHasIgnoreContextRole = false;

        let tenantApplicationIdList: string[] = [];

        for (const tenantApplication of tenantApplications) {
            if (tenantApplication.tenantId.toString() === tenantId?.toString()) {
                tenantApplicationIdList.push(tenantApplication.applicationId.toString());
            }
        }

        const isPartOfTenantList = (tenantApplicationId: string) => {
            return tenantApplicationIdList.filter((a) => a === tenantApplicationId).length > 0;
        };

        // Build applicationPermissions
        for (const tenantApplication of tenantApplications) {
            const tenantApplicationRoles = tenantApplication.roles.filter((a) => !a.isSystemRole);
            // If user's tenant is system default will be true
            const isApplicationPartOfUsersTenant =
                isPartOfTenantList(tenantApplication.applicationId.toString()) &&
                tenantId?.toString() !== DEFAULT_SYSTEM_GUID.toString();

            if (tenantApplicationRoles.length === 0 || !isApplicationPartOfUsersTenant) {
                continue;
            }

            applicationRoles.push({
                id: tenantApplication.application.id.toString(),
                description: tenantApplication.application.description,
                type: "application",
            });

            for (const role of tenantApplicationRoles) {
                applicationRoles.push({
                    id: role.id.toString(),
                    description: role.description,
                    type: "role",
                    applicationId: tenantApplication.application.id.toString(),
                    applicationRole: role.applicationRole,
                });

                if (isUpdateUser(user)) {
                    const userRoles = user.userRoles
                        .filter((a) => !a.role?.isSystemRole)
                        .filter((a) => a.roleId.toString() === role.id.toString());

                    if (userRoles.length > 0) {
                        newSelectedRoleIds.push(userRoles[0].roleId.toString());

                        if (role.ignoreContext) {
                            userHasIgnoreContextRole = true;
                        }
                    }
                }
            }
        }

        setApplicationRoles(applicationRoles.sort(roleSortHandler));
        setExpandedRoleId(
            applicationRoles
                .filter((appRole) => !appRole.applicationId)
                .map((appRole) => appRole.id),
        );
        setIsLoading(false);
        setPendingChanges(false);
        setSelectedRoleIds(newSelectedRoleIds);
        setHasIgnoreContextRole(userHasIgnoreContextRole);
    }, [isLoading, isReady, selectedRoleIds.length, tenantApplications, user]);

    React.useEffect(() => {
        // Check for pending changes
        if (pendingChanges) {
            return;
        }

        if (isUpdateUser(user)) {
            if (pendingUserRoleContextValues.length > 0) {
                // If we have any pending role to contextValue pending values
                setPendingChanges(true);
            } else if (userRolesChanged(user.userRoles, selectedRoleIds)) {
                // If any of the roles for the user changed
                setPendingChanges(true);
            }
        } else {
            // If user is NEW
            setPendingChanges(true);
        }
    }, [selectedRoleIds, pendingChanges, user, pendingUserRoleContextValues]);

    // Validate Form
    React.useEffect(() => {
        if (!isReady || isLoading) {
            return;
        }

        // Validate User
        const isUsernameValid = isEmailValid(user.username);

        if (!isUsernameValid) {
            setUsernameErrorMessage(translate("Email address is not valid."));
            setIsCoreFieldsValid(false);

            return;
        }
        setUsernameErrorMessage(undefined);

        // Validate Application Permissions
        if (selectedRoleIds.length === 0) {
            // No permission selected
            setSelectedRoleError(translate("Need at least one application role selected."));
            setIsCoreFieldsValid(false);

            return;
        }
        setSelectedRoleError(undefined);

        setIsCoreFieldsValid(true);
    }, [isReady, isLoading, user.username, selectedRoleIds]);

    const handleSelectionChanged = (event: Partial<{ selectedRowKeys: string[] }>): void => {
        setSelectedRoleIds(event.selectedRowKeys || []);
        onUserChanged({
            ...user,
        });
    };

    const handleCancel = async () => {
        if (onReset) {
            await onReset();
            setSelectedRoleIds([]);
            setPendingChanges(false);

            return;
        }

        history.push(UserListPath);
    };

    const handleSubmit = async () => {
        try {
            setInProgress(true);
            const userRoles: string[] = [];

            for (const roleId of selectedRoleIds) {
                if (isApplication(tenantApplications, roleId)) {
                    continue;
                } else {
                    userRoles.push(roleId);
                }
            }

            for (const roleId of selectedRoleIds) {
                if (isApplication(tenantApplications, roleId)) {
                    const applicationRoles = getApplicationRoles(tenantApplications, roleId);

                    for (const applicationUserRole of applicationRoles) {
                        userRoles.push(applicationUserRole.id.toString());
                    }
                }
            }

            for (const value of pendingUserRoleContextValues) {
                if (value.type === "REMOVE") {
                    await accountServiceContext.userRoleContextValues.remove(value.value.id);
                } else {
                    const newValue: PartialBy<IUserRoleContextValue, "id"> = value.value;
                    delete newValue.id;
                    await accountServiceContext.userRoleContextValues.insert(value.value);
                }
            }

            await onSubmit(userRoles);
            setPendingUserRoleContextValues([]);
            setPendingChanges(false);

            return true;
        } catch {
            return false;
        } finally {
            if (componentIsMounted.current) {
                setInProgress(false);
            }
        }
    };

    const createPropertyChangedHandle = (propertyKey: keyof IUpdateUser) => (newValue: string) => {
        setPendingChanges(true);
        onUserChanged({
            ...user,
            [propertyKey]: newValue,
        });
    };

    const getUserFullName = (user: IEditUser) => {
        // TODO: DASH-162 : Find better way to convey context of the users first and last name
        return translate("{0} {1}", user.firstName || "", user.lastName || "");
    };

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    const isReadyToEdit = () => !isReady || isLoading;

    const tabActiveCss = (tabName: string): string => {
        return tabName === activeTabName ? "active" : "";
    };

    const createHandleSetActiveTab = (tabName: string) => (
        event: React.FormEvent<HTMLAnchorElement>,
    ) => {
        event.preventDefault();
        setActiveTabName(tabName);
    };

    // We make sure the ID is not a new id, meaning not added to DB yet.
    const isNotNewGuidId = (id: Guid) => id.toString() !== NEW_GUID_ID.toString();
    // Return a list with the contextValueId and userRoleId values filtered out
    const filterOutExistingUserRoleContextValue = (
        existingValues: IUserRoleContextValueChange[],
        userRoleContextValue: IUserRoleContextValue,
    ) =>
        existingValues.filter(
            (a) =>
                !(
                    a.value.contextValueId.toString() ===
                        userRoleContextValue.contextValueId.toString() &&
                    a.value.userRoleId.toString() === userRoleContextValue.userRoleId.toString()
                ),
        );

    const handleUserRoleContextValuesChanged = (
        userRoleContextValues: IUserRoleContextValueChange[],
    ) => {
        let newValues = [...pendingUserRoleContextValues];

        for (const changeValue of userRoleContextValues) {
            if (changeValue.type === "ADD") {
                newValues = [
                    // Filter out any "REMOVE" typed values
                    ...filterOutExistingUserRoleContextValue(newValues, changeValue.value),
                    {
                        type: "ADD",
                        value: changeValue.value,
                    },
                ];
            } else if (changeValue.type === "REMOVE") {
                if (isNotNewGuidId(changeValue.value.id)) {
                    // Add "REMOVE" userRoleContextValue to pending, this will remove from DB on save
                    newValues = [
                        // Cleanup of duplicates
                        ...filterOutExistingUserRoleContextValue(newValues, changeValue.value),
                        {
                            type: "REMOVE",
                            value: changeValue.value,
                        },
                    ];
                } else {
                    newValues = [
                        // Removes any pending "ADD" types for the userRoleId and contextValueId
                        ...filterOutExistingUserRoleContextValue(newValues, changeValue.value),
                    ];
                }
            }
        }
        setPendingUserRoleContextValues(newValues);
    };

    const handleBackClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        if (isUpdateUser(user) && pendingChanges) {
            setIsPendingChangesPopupOpen(true);

            return;
        }

        history.push(UserListPath);
    };

    const ModalContentRender = () => {
        const handleClose = () => {
            setIsPendingChangesPopupOpen(false);
        };

        const handleDiscard = () => {
            history.push(UserListPath);
        };

        const handleSave = async () => {
            if (await handleSubmit()) {
                history.push(UserListPath);
            }
        };

        return (
            <div className="pending-changes-modal">
                <div className="pending-changes-modal__content">
                    {translate(
                        "You have unsaved changes that will be lost. Would you like to save changes before you continue?",
                    )}
                </div>
                <div className="pending-changes-modal__actions">
                    <Button onClick={handleClose}>{translate("Cancel")}</Button>
                    <Button onClick={handleDiscard}>{translate("Discard")}</Button>
                    <Button styledAs="primary" type="submit" onClick={handleSave}>
                        {translate("Save")}
                    </Button>
                </div>
            </div>
        );
    };

    const handlePendingChangesPopupHidden = () => {
        setIsPendingChangesPopupOpen(false);
    };

    return (
        <div className="user-edit" data-test-selector="user-edit">
            <form onSubmit={handleFormSubmit}>
                <div className="header">
                    <h1 className="sr-only">{translate("User Edit")}</h1>
                    <div className="page-actions">
                        <div>
                            <i className="dx-icon dx-icon-back" />
                            <NavLink
                                className="back-link"
                                to={UserListPath}
                                onClick={handleBackClick}
                            >
                                {translate("Users")}
                            </NavLink>
                        </div>
                        <div className="page-buttons">
                            {pendingChanges && (
                                <Button
                                    disabled={isReadyToEdit()}
                                    type="reset"
                                    onClick={handleCancel}
                                >
                                    {translate("Cancel")}
                                </Button>
                            )}
                            <Button
                                disabled={
                                    !isReady ||
                                    isLoading ||
                                    !pendingChanges ||
                                    !isCoreFieldsValid ||
                                    !isValid ||
                                    inProgress
                                }
                                inProgress={inProgress || isWorking}
                                styledAs="primary"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                {submitActionText ?? translate("Submit")}
                            </Button>
                        </div>
                    </div>
                    <h2>{getUserFullName(user)}</h2>
                </div>
                <div className="main-content">
                    <div className="tabs">
                        <div className="tab-bar">
                            <a
                                className={`tab-link ${tabActiveCss("details-tab")}`}
                                href="#details-tab"
                                onClick={createHandleSetActiveTab("details-tab")}
                            >
                                {translate("Details")}
                            </a>
                            <UserEditApplicationTabs
                                createHandleSetActiveTab={createHandleSetActiveTab}
                                tabActiveCss={tabActiveCss}
                                tenantApplications={tenantApplicationContexts}
                                user={user}
                            />
                        </div>
                        <div className="tab-list">
                            <div
                                className={`tab tab-item details-tab ${tabActiveCss(
                                    "details-tab",
                                )}`}
                                id="details-tab"
                            >
                                <div className="column-1">
                                    {detailsDescription && (
                                        <div className="description">{detailsDescription}</div>
                                    )}
                                    <TextBox
                                        className="username"
                                        disabled={isReadyToEdit()}
                                        errorMessage={usernameErrorMessage}
                                        label={translate("Email")}
                                        value={user.username}
                                        required
                                        onChanged={createPropertyChangedHandle("username")}
                                    />
                                    <TextBox
                                        className="firstName"
                                        disabled={isReadyToEdit()}
                                        label={translate("First Name")}
                                        value={user.firstName}
                                        required
                                        onChanged={createPropertyChangedHandle("firstName")}
                                    />
                                    <TextBox
                                        className="lastName"
                                        disabled={isReadyToEdit()}
                                        label={translate("Last Name")}
                                        value={user.lastName}
                                        required
                                        onChanged={createPropertyChangedHandle("lastName")}
                                    />
                                    {/* <TextBox className="phoneNumber" 
                                        label={translate("Phone Number")}
                                        value={user.phoneNumber}
                                        disabled={isReadyToEdit()}
                                        onChanged={createPropertyChangedHandle("phoneNumber")}
                                    /> */}
                                    {moreFormElements}
                                    <div className="user-actions">{userActions}</div>
                                </div>
                                <div className="column-2">
                                    <h3>{translate("User Account Access")}</h3>
                                    {selectedRoleError && (
                                        <div className="--error">{selectedRoleError}</div>
                                    )}
                                    <TreeList
                                        columnAutoWidth={false}
                                        dataSource={applicationRoles}
                                        disabled={isReadyToEdit()}
                                        highlightChanges={false}
                                        id="application-permissions"
                                        keyExpr="id"
                                        parentIdExpr="applicationId"
                                        selectedRowKeys={selectedRoleIds}
                                        showBorders={false}
                                        showColumnHeaders={false}
                                        showRowLines={false}
                                        onSelectionChanged={handleSelectionChanged}
                                        expandedRowKeys={expandedRoleId}
                                    >
                                        <Selection mode="multiple" recursive />
                                        <Column dataField="description" cellTemplate="tooltip" />
                                        <Template
                                            name="tooltip"
                                            render={(templateProps: TooltipProps) => {
                                                const applicationRole =
                                                    templateProps.data.applicationRole ||
                                                    "Tenant Application";

                                                const roleDescription = ROLE_TEXT[applicationRole];
                                                return (
                                                    <div>
                                                        <span
                                                            className={
                                                                !templateProps.data.applicationId
                                                                    ? "bold"
                                                                    : ""
                                                            }
                                                        >
                                                            {templateProps.text}
                                                        </span>{" "}
                                                        {templateProps.data.applicationId &&
                                                            roleDescription && (
                                                                <>
                                                                    <div
                                                                        className="info-popover"
                                                                        id={`popover-${templateProps.data.id}`}
                                                                        onMouseOver={() =>
                                                                            setPopoverId(
                                                                                templateProps.data
                                                                                    .id,
                                                                            )
                                                                        }
                                                                        onMouseLeave={() =>
                                                                            setPopoverId("")
                                                                        }
                                                                    >
                                                                        <TooltipIcon />
                                                                    </div>
                                                                    <Popover
                                                                        target={`#popover-${templateProps.data.id}`}
                                                                        position="right"
                                                                        width={300}
                                                                        visible={
                                                                            templateProps.data
                                                                                .id === popoverId
                                                                        }
                                                                    >
                                                                        {roleDescription}
                                                                    </Popover>
                                                                </>
                                                            )}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </TreeList>
                                </div>
                            </div>
                            <UserEditApplicationDetailsTabs
                                tabActiveCss={tabActiveCss}
                                tenantApplications={tenantApplicationContexts}
                                user={user}
                                onUserRoleContextValuesChanged={handleUserRoleContextValuesChanged}
                            />
                        </div>
                    </div>
                </div>
            </form>
            <Popup
                contentRender={ModalContentRender}
                dragEnabled={false}
                // height={540}
                height="auto"
                showCloseButton={false}
                titleRender={() => <h6 className="title">{translate("Unsaved Changes")}</h6>}
                visible={isPendingChangesPopupOpen}
                // width={660}
                closeOnOutsideClick
                showTitle
                onHiding={handlePendingChangesPopupHidden}
            />
        </div>
    );
};

interface TooltipProps {
    data: {
        description: string;
        applicationId: string;
        id: string;
        applicationRole?: string;
    };
    text: string;
}

const UserEditApplicationTabs: React.FC<{
    user: IEditUser;
    tenantApplications: ITenantApplication[];
    tabActiveCss: (tabName: string) => string;
    createHandleSetActiveTab: (
        tabName: string,
    ) => (event: React.FormEvent<HTMLAnchorElement>) => void;
}> = ({ user, tenantApplications, tabActiveCss, createHandleSetActiveTab }) => {
    const tenantAppId = tenantApplications.map((tenantApp) => String(tenantApp.tenantId));
    if (
        !isUpdateUser(user) ||
        user.userRoles.length === 0 ||
        user.userRoles.filter((roleItem) => tenantAppId.includes(String(roleItem.tenantId)))
            .length === 0 ||
        user.userRoles.some((userItem) => userItem.role?.ignoreContext)
    ) {
        return <></>;
    }

    return (
        <>
            {tenantApplications.map((application) => {
                const tabName = `application-${application.applicationId.toString()}-tab`;

                return (
                    <a
                        key={tabName}
                        className={`tab-link ${tabActiveCss(tabName)}`}
                        href={`#${tabName}`}
                        onClick={createHandleSetActiveTab(tabName)}
                    >
                        {application.application.description}
                    </a>
                );
            })}
        </>
    );
};

const UserEditApplicationDetailsTabs: React.FC<{
    user: IEditUser;
    tenantApplications: ITenantApplication[];
    tabActiveCss: (tabName: string) => string;
    onUserRoleContextValuesChanged: (userRoleContextValues: IUserRoleContextValueChange[]) => void;
}> = ({ user, tenantApplications, tabActiveCss, onUserRoleContextValuesChanged }) => {
    if (!isUpdateUser(user) || user.userRoles.filter((a) => !a.role?.ignoreContext).length === 0) {
        return <></>;
    }

    return (
        <>
            {tenantApplications.map((application) => {
                const tabName = `application-${application.applicationId.toString()}-tab`;

                return (
                    <div
                        key={tabName}
                        className={`tab tab-item application-tab ${tabActiveCss(tabName)}`}
                        id={tabName}
                    >
                        <UserEditApplicationContexts
                            applicationId={application.applicationId.toString()}
                            user={user}
                            onUserRoleContextValuesChanged={onUserRoleContextValuesChanged}
                        />
                    </div>
                );
            })}
        </>
    );
};

export default UserEdit;
