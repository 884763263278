import React from "react";
import { useHistory, useParams } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { confirm } from "devextreme/ui/dialog";
import Guid from "devextreme/core/guid";

import translate from "../../i18n/i10n/translate";
import Button from "../controls/Button";
import accountServiceContext from "../../odata/accountServiceContext";
import IUser from "../../odata/entities/IUser";
import removeUndefinedOrNull from "../../core/removeUndefinedOrNull";
import useCurrentUser from "../../user/useCurrentUser";

import { UserListPath } from "./UserList";
import updateUser from "./update/updateUser";
import IUpdateUser from "./models/IUpdateUser";
import UserEditComponent from "./edit/UserEdit";
import IEditUser, { isUpdateUser } from "./models/IEditUser";
import "./UserEdit.tsx.scss";

const localizeDate = (date: Date) => {
    // TODO: DASH-162 : Use CurrentUser's Locale
    return date.toLocaleDateString();
};

const localizeTime = (date: Date) => {
    // TODO: DASH-162 : Use CurrentUser's Locale
    return date.toLocaleTimeString();
};

const defaultUser: IUpdateUser = {
    id: "",
    username: "",
    firstName: "",
    lastName: "",
    lastLoggedIn: new Date(),
    userRoles: [],
};

const toFormUser = (user: IUser): IUpdateUser => ({
    ...defaultUser,
    ...removeUndefinedOrNull(user),
});

const UserEdit = () => {
    const currentUser = useCurrentUser();
    const { userId } = useParams<{ userId: string }>();
    const history = useHistory();

    const [isReady, setIsReady] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isWorking, setIsWorking] = React.useState(true);
    const [tenantId, setTenantId] = React.useState<Guid | undefined>();
    const [user, setUser] = React.useState<IUpdateUser>(defaultUser);

    // Load User from the account service context
    const loadUser = async (userId: string) => {
        setIsReady(false);
        const user = await accountServiceContext.users.byKey(userId, {
            expand: ["userRoles", "userRoles.userRoleContextValues", "userRoles.role", "userRoles.role.rolePermissions", "userRoles.role.rolePermissions.permission"],
        });
        setTenantId(user.tenantId);
        setUser(toFormUser(user));
        setIsReady(true);
        setIsWorking(false);
        setIsLoading(false);
    };

    // Load the initial user and on any changes of the userId.
    React.useEffect(() => {
        loadUser(userId);
    }, [userId]);

    const handleLockUser = async () => {
        try {
            setIsWorking(true);

            if (
                !(await confirm(
                    translate("Are you sure you want to lock this user?"),
                    translate("Lock User"),
                ))
            ) {
                return;
            }

            const response = await fetch(`odata/users(${user.id})/DisableUser`, {
                method: "GET",
                headers: new Headers({ Authorization: "Bearer " + currentUser.access_token }),
            });

            if (response.ok) {
                notify(
                    translate(
                        "The user is locked and will no longer have access to any applications.",
                    ),
                    "success",
                );
                await loadUser(userId);
            } else {
                notify(
                    translate(
                        "Error of '{0}' returned from lock of user account.",
                        response.statusText,
                    ),
                    "error",
                );
            }
        } catch (ex) {
            notify(translate("Error returned from user lock request."), "error");
        } finally {
            setIsWorking(false);
        }
    };

    const handleUnlockUser = async () => {
        try {
            setIsWorking(true);

            if (
                !(await confirm(
                    translate("Are you sure you want to unlock this user?"),
                    translate("Unlock User"),
                ))
            ) {
                return;
            }

            const response = await fetch(`odata/users(${user.id})/EnableUser`, {
                method: "POST",
                headers: new Headers({ Authorization: "Bearer " + currentUser.access_token }),
            });

            if (response.ok) {
                notify(
                    translate("The user is now unlocked and has access to applications."),
                    "success",
                );
                await loadUser(userId);
            } else {
                notify(
                    translate(
                        "Error of '{0}' returned from unlock user request.",
                        response.statusText,
                    ),
                    "error",
                );
            }
        } catch (ex) {
            notify(translate("Error returned from user unlock request."), "error");
        } finally {
            setIsWorking(false);
        }
    };

    const handleDeleteUser = async () => {
        let redirect = false;

        try {
            setIsWorking(true);

            if (
                !(await confirm(
                    translate("Are you sure you want to delete this user?"),
                    translate("Confirm Delete User"),
                ))
            ) {
                return;
            }
            await accountServiceContext.users.remove(user.id);
            notify(translate("The user was successfully deleted."), "success");
            redirect = true;
        } catch (ex) {
            notify(translate("Error returned from delete of user."), "error");
        } finally {
            setIsWorking(false);
        }

        if (redirect) {
            history.replace(UserListPath);
        }
    };

    const handleUserChanged = (user: IEditUser) => {
        if (isUpdateUser(user)) {
            setUser(user);
        }
    };

    const handleSubmit = async (selectedRoleIds: string[]) => {
        const notifyOfError = () =>
            notify(
                translate("Failed to update user, please check details and try again."),
                "error",
            );

        try {
            const { successful } = await updateUser(user, selectedRoleIds);

            if (!successful) {
                notifyOfError();

                return;
            }
            setIsLoading(true);
            setTenantId(undefined);
            setUser({ ...user });
            setIsReady(false);

            await loadUser(userId);
        } catch (ex) {
            notifyOfError();
        }
    };

    const handleReset = async () => {
        setIsLoading(true);
        setTenantId(undefined);
        setUser({ ...user });
        setIsReady(false);

        await loadUser(userId);
    };

    return (
        <UserEditComponent
            isReady={isReady}
            isWorking={isWorking}
            moreFormElements={
                <>
                    <div className="lastLoggedIn">
                        <span>{translate("Last Login: ")}</span>
                        <span className="--bold">
                            {/* TODO: DASH-162 : Find better way to convey context Last Login */}
                            {translate(
                                "{0} at {1}",
                                localizeDate(user.lastLoggedIn),
                                localizeTime(user.lastLoggedIn),
                            )}
                        </span>
                    </div>
                    <div className="user-actions">
                        {user.deactivateOn ? (
                            <Button
                                disabled={isLoading}
                                displayedAs="link"
                                styledAs="primary"
                                onClick={handleUnlockUser}
                            >
                                {translate("Unlock User")}
                            </Button>
                        ) : (
                            <Button
                                disabled={isLoading}
                                displayedAs="link"
                                styledAs="primary"
                                onClick={handleLockUser}
                            >
                                {translate("Lock User")}
                            </Button>
                        )}
                        <Button
                            disabled={isLoading}
                            displayedAs="link"
                            styledAs="primary"
                            onClick={handleDeleteUser}
                        >
                            {translate("Delete")}
                        </Button>
                    </div>
                </>
            }
            submitActionText={translate("Save")}
            tenantId={tenantId}
            user={user}
            isValid
            onReset={handleReset}
            onSubmit={handleSubmit}
            onUserChanged={handleUserChanged}
        />
    );
};

export default UserEdit;
