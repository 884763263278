import IUserRole from "../../../odata/entities/IUserRole";

interface IRemoveRole {
    id: string;
    roleId: string;
}
interface ICreateRole {
    roleId: string;
}
type SingleArg = Array<IRemoveRole | ICreateRole>;

/**
 * This will create a changed details array.
 * A role that should be removed will have an id and a roleId.
 * A role that should be added will only have a roleId.
 *
 * @param userRoleIds The users current roles.
 * @param newUserRoleIds The new roles that should be assigned to a User.
 * @returns Will return a list of Roles with Id's and Roles that do not contain id's.
 */
const getUserRolesChangedDetail = (
    userRoleIds: IUserRole[],
    newUserRoleIds: string[],
): SingleArg => {
    // These need to be .toString(), since they are Guid's this will get the string representation.
    // Get list of roles that should be added
    const toAddRoles = newUserRoleIds.filter((roleId) => {
        // Check the roleId from the new user list, if not in list it will be added to the toAddRoles.
        const roles = userRoleIds
            .filter((a) => !a.role?.isSystemRole)
            .filter((userRole) => userRole.roleId.toString() === roleId);

        return roles.length === 0;
    });
    // Get a list of Roles that are in the userRolesId (existing) and not in the newUserRoleIds (new list of roles for user).
    const toRemoveRoles = userRoleIds
        .filter((a) => !a.role?.isSystemRole)
        .filter((userRole) => {
            // Check existing userRole is not included in new list.
            const roles = newUserRoleIds.filter(
                (newRoleId) => newRoleId.toString() === userRole.roleId.toString(),
            );

            return roles.length === 0;
        });

    // Consolidate the lists, creating the structure they should be in based on if they should be removed or add to user.
    return [
        ...toAddRoles.map((roleId) => ({
            roleId,
        })),
        ...toRemoveRoles.map((role) => ({
            id: role.id.toString(),
            roleId: role.roleId.toString(),
        })),
    ];
};

export default getUserRolesChangedDetail;
