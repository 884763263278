import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext, {getTenantsDataSource} from "../../odata/accountServiceContext";
import TenantContext from "./TenantContext";

export default class TenantApplicationEnvironmentsTemplate extends React.Component {
    static contextType = TenantContext;

    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.tenantApplicationEnvironmentsDataSource = getTenantApplicationEnvironmentsDataSource(props.data.key);
            this.environmentsDataSource = getEnvironmentsDataSource();
            this.tenantsDataSource = getTenantsDataSource();
        }
    }

    componentDidMount() {
        this.tenantId = this.context.tenantId;
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.tenantId = this.tenantId;
        e.data.tenantApplicationId = this.props.data.key;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.tenantApplicationEnvironmentsDataSource}
                popupTile="Tenant Application Environment Info"
                onInitNewRow={this.onInitNewRow}>

                {/* Grid Columns */}
                <Column dataField="tenantId" dataType="Guid" caption="Tenant" allowEditing={false} visible={false}>
                    <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="environmentId" dataType="Guid" caption="Environment">
                    <Lookup dataSource={this.environmentsDataSource} displayExpr="name" valueExpr="id"/>
                </Column>
                <Column dataField="turnstileInstanceId" caption="Turnstile Instance Id" />
                <Column dataField="redirectUris" caption="Redirect Uris (csv)"/>
                <Column dataField="environment.name" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId", "environmentId", "turnstileInstanceId"]}/>
                <Item itemType="group" colCount={1} colSpan={2} items={["redirectUris"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2} tabs={[{title: "Audit Info", colCount: 2, items: ["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}]}/>

            </BaseDataGrid>
        );
    }
}

function getTenantApplicationEnvironmentsDataSource(tenantApplicationId) {
    return {
        store: accountServiceContext.tenantApplicationEnvironments,
        filter: ['tenantApplicationId', '=', tenantApplicationId],
        sort: 'environment.name'
    };
}

function getEnvironmentsDataSource() {
   return {
       store: accountServiceContext.environments,
       select: ['id', 'name'],
       sort: 'name'
   };
}
