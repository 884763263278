import { IDENTITY_CONFIG } from "../auth/authConstants";

interface IAuthenticatedUserPermissions {
    canViewUsers: boolean;
    canViewTenants: boolean;
    canViewApplications: boolean;
    canViewEnvironments: boolean;
    canViewGroups: boolean;
}

interface AuthenticatedUser {
    // TODO: DASH-162 : Fill with Locale from users session
    access_token: string;
    profile: {
        epiTenantId: string;
        roles: string[];
        epiUserName: string;
        epiUserDisplayName: string;
        preferred_username: string;
    };
    permissions: IAuthenticatedUserPermissions;
}

const SYSTEM_OR_USERADMIN_ROLES = ['SYS_System', 'SYS_UserAdmin']
const SYSTEM_ROLES = ['SYS_System']

const setUserPermissions = (roles: Array<string>): IAuthenticatedUserPermissions => {
    return {
        canViewUsers: roles.some(role => SYSTEM_OR_USERADMIN_ROLES.includes(role)),
        canViewTenants: roles.some(role => SYSTEM_OR_USERADMIN_ROLES.includes(role)),
        canViewApplications: roles.some(role => SYSTEM_ROLES.includes(role)),
        canViewEnvironments: roles.some(role => SYSTEM_OR_USERADMIN_ROLES.includes(role)),
        canViewGroups: roles.some(role => SYSTEM_OR_USERADMIN_ROLES.includes(role)),
    }
}

let user: AuthenticatedUser | undefined = undefined;

// TODO: Remove and use user.profile.groupId
export const DEFAULT_IDENTITY_CUSTOMER_ID = "B6BB2D0C-D0D7-447E-9836-6FB446068FDB";

export const useCurrentUser = (): AuthenticatedUser => {
    if (!user) {
        user = JSON.parse(
            sessionStorage.getItem(
                `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`,
            ) || "{}",
        ) as AuthenticatedUser;

        if (user.profile)
            user.permissions = setUserPermissions(user.profile.roles);
    }

    return user;
};

export default useCurrentUser;
