import React from 'react';

import {Button} from "devextreme-react/ui/button";
import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item, Tab} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import notify from 'devextreme/ui/notify';
import {confirm, alert} from 'devextreme/ui/dialog';
import accountServiceContext from "../../odata/accountServiceContext";
import UserRolesTemplate from "./UserRolesTemplate";
import UserTenantsTemplate from "./UserTenantsTemplate";
import {IDENTITY_CONFIG} from "../../auth/authConstants";

export default class UserManagement extends React.Component {
    constructor(props) {
        super(props);
        const user = JSON.parse(sessionStorage.getItem(`oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`));
        this.access_token = user.access_token;
    }

    renderToolbar = () => {
        return (
            <div style={{textAlign: "right"}}>
                <Button width={180} text="Reset Password" type="normal" stylingMode="contained" onClick={this.onResetPassword}/>
                <Button width={180} text="Lock User" type="normal" stylingMode="contained" onClick={this.onDisableUser}/>
                <Button width={180} text="Unlock User" type="normal" stylingMode="contained" onClick={this.onEnableUser}/>
            </div>
        );
    };

    onEditingStart = (e) => {
        this.currentKey = e.key;
    };

    onRowInserted = (e) => {
        if (e.data.password) {
            alert(`User created with password: ${e.data.password}`, "Success");
        } else {
            alert(`User created and attached to existing identity`, "Success");
        }
    };

    onDisableUser = async (e) => {
        if (!await confirm("Are you sure you want to lock this user?", "Confirm")) {
            return;
        }

        const response = await fetch(`odata/users(${this.currentKey})/DisableUser`, {
            method: 'GET',
            headers: new Headers({'Authorization': 'Bearer ' + this.access_token})
        });
        if (response.ok) {
            await this.dataGrid.instance.getDataSource().reload();
            setTimeout(() => {
                notify("User locked", "success")
            });
        } else {
            notify(response.statusText, "error");
        }
    };

    onEnableUser = async (e) => {
        if (!await confirm("Are you sure you want to unlock this user?", "Confirm")) {
            return;
        }

        const response = await fetch(`odata/users(${this.currentKey})/EnableUser`, {
            method: 'POST',
            headers: new Headers({'Authorization': 'Bearer ' + this.access_token})
        });
        if (response.ok) {
            await this.dataGrid.instance.getDataSource().reload();
            setTimeout(() => {
                notify("User unlocked", "success")
            });
        } else {
            notify(response.statusText, "error");
        }
    };

    onResetPassword = async (e) => {
        if (!await confirm("Are you sure you want to reset this user's password?", "Confirm")) {
            return;
        }

        const response = await fetch(`odata/users(${this.currentKey})/ResetPassword`, {
            method: 'GET',
            headers: new Headers({'Authorization': 'Bearer ' + this.access_token})
        });
        if (response.ok) {
            const password = await response.json();
            alert(`User's password has been reset to: ${password.value}`, "Success");
        } else {
            notify(response.statusText, "error");
        }
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={{store: accountServiceContext.users, sort: 'username'}}
                popupTitle="User Info"
                onEditingStart={this.onEditingStart}
                onRowInserted={this.onRowInserted}>

                {/* Grid Columns */}
                <Column dataField="tenantId" dataType="Guid" caption="Tenant">
                    <Lookup dataSource={{store: accountServiceContext.tenants, select: ['id', 'companyName'], sort: 'companyName'}} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="groupId" dataType="Guid" caption="Group">
                    <Lookup dataSource={{store: accountServiceContext.groups, select: ['id', 'name'], sort: 'name'}} displayExpr="name" valueExpr="id"/>
                </Column>
                <Column dataField="username" caption="Username"/>
                <Column dataField="firstName" caption="First Name"/>
                <Column dataField="lastName" caption="Last Name"/>
                <Column dataField="identitySub" caption="Identity Sub" visible={false} allowEditing={false}/>
                <Column dataField="lastLoggedIn" caption="Last Logged In" dataType="datetime" allowEditing={false}/>
                <Column dataField="deactivateOn" caption="Deactivate On" dataType="datetime" allowEditing={false}/>
                <Column dataField="userRoles" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false} editCellComponent={UserRolesTemplate}/>
                <Column dataField="userTenants" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false} editCellComponent={UserTenantsTemplate}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={1} colSpan={2}>
                    <Item render={this.renderToolbar}/>
                </Item>
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId", "username", "groupId", "firstName", "lastName", "identitySub", "lastLoggedIn", "deactivateOn"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2}>
                    <Tab title="Roles" items={[{dataField: "userRoles", label: {visible: false}}]}/>
                    <Tab title="Tenants" items={[{dataField: "userTenants", label: {visible: false}}]}/>
                    <Tab title="Audit Info" colCount={2} items={["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}/>
                </Item>

            </BaseDataGrid>
        );
    }
}

