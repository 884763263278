import React from 'react';

import {Column} from 'devextreme-react/data-grid';
import {Item, Tab} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext from "../../odata/accountServiceContext";

export default class EnvironmentManagement extends React.Component {
    render() {
        return (
            <BaseDataGrid
                dataSource={{store: accountServiceContext.environments, sort: 'name'}}
                popupTitle="Environment Info">

                {/* Grid Columns */}
                <Column dataField="name" caption="Name"/>
                <Column dataField="description" caption="Description"/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", {itemType: "empty"}, "name", "description"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2}>
                    <Tab title="Audit Info" colCount={2} items={["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}/>
                </Item>

            </BaseDataGrid>
        );
    }
}
