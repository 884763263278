/* eslint-disable no-console */
import { UserManager, WebStorageStateStore, Log } from "oidc-client";

import { IDENTITY_CONFIG, METADATA_URL } from "./authConstants";

export default class AuthService {
    UserManager;

    constructor() {
        this.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            metadataUrl: METADATA_URL,
        });

        // Logger
        Log.logger = console;
        Log.level = Log.DEBUG;
        this.UserManager.events.addUserLoaded((_) => {
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                this.navigateToScreen();
            }
        });

        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("token expired");
            this.signinSilent();
        });
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then((user) => {
            console.log("signed in", user);
        });
    };

    getUser = async () => {
        const user = await this.UserManager.getUser();

        if (!user) {
            return this.UserManager.signinRedirectCallback();
        }

        return user;
    };

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");

        return JSON.parse(window.atob(base64));
    };

    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname);
        this.UserManager.signinRedirect({});
    };

    navigateToScreen = () => {
        const redirectUri = localStorage.getItem("redirectUri");

        if (redirectUri) {
            localStorage.removeItem("redirectUri");
            window.location.replace(redirectUri);

            return;
        }
        window.location.replace("/");
    };

    isAuthenticated = () => {
        const user = JSON.parse(
            sessionStorage.getItem(
                `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`,
            ),
        );

        return !!user && !!user.access_token;
    };

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                console.log("signed in", user);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    clearSessionStorage = () => {
        sessionStorage.clear();
    };

    logout = () => {
        this.UserManager.signoutRedirect({
            // eslint-disable-next-line @typescript-eslint/camelcase
            id_token_hint: localStorage.getItem("id_token"),
        });
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            window.location.replace("/");
        });
        this.UserManager.clearStaleState();
    };
}
