import { Column, Pager, Paging } from "devextreme-react/data-grid";
import Guid from "devextreme/core/guid";
import { DataSourceOptions } from "devextreme/data/data_source";
import React from "react";
import notify from "devextreme/ui/notify";

import translate from "../../../i18n/i10n/translate";
import accountServiceContext from "../../../odata/accountServiceContext";
import IContext from "../../../odata/entities/IContext";
import IContextValue from "../../../odata/entities/IContextValue";
import IUserRoleContextValue, { NEW_GUID_ID } from "../../../odata/entities/IUserRoleContextValue";
import CheckBox from "../../controls/Checkbox";
import DataGrid from "../../controls/DataGrid";
import SelectBox from "../../controls/SelectBox";
import IUpdateUser from "../models/IUpdateUser";
import TextBox from "../../controls/TextBox";
import RadioGroup from "../../controls/RadioGroup";
import IUserRole from "../../../odata/entities/IUserRole";
import IRole from "../../../odata/entities/IRole";
import "./UserEditApplicationContexts.tsx.scss";

export type IUserRoleContextValueChange = { type: "REMOVE" | "ADD"; value: IUserRoleContextValue };

type Props = {
    user: IUpdateUser;
    applicationId: string;
    onUserRoleContextValuesChanged: (userRoleContextValues: IUserRoleContextValueChange[]) => void;
};

const ShowAllFilterType = "show-all";
const ShowAssignedToUserFilterType = "show-assigned-to-user";
const ShowUnassigned = "show-unassigned";
type ShowFilterType =
    | typeof ShowAllFilterType
    | typeof ShowAssignedToUserFilterType
    | typeof ShowUnassigned;
type UserRoleContextValueFilterType = (string | string[])[] | (string | string[])[][];

// Build ContextValues.value contains based on searchFilter.
const buildValueSearchFilter = (searchFilter: string) => {
    if (searchFilter === "") {
        return [];
    }

    return ["and", ["value", "contains", searchFilter]];
};

const UserEditApplicationContexts: React.FC<Props> = ({
    user,
    applicationId,
    onUserRoleContextValuesChanged,
}) => {
    const [searchFilter, setSearchFilter] = React.useState<string>("");
    const [contexts, setContexts] = React.useState<IContext[]>([]);
    const [selectedContext, setSelectedContext] = React.useState<IContext | undefined>(undefined);
    const [userRoles, setUserRoles] = React.useState<IRole[]>([]);
    const [dataSourceOptions, setDataSourceOptions] = React.useState<DataSourceOptions>({
        store: accountServiceContext.contextValues,
        sort: "value",
    });
    const [userRoleContextValues, setUserRoleContextValues] = React.useState<
        IUserRoleContextValue[]
    >([]);

    // Show Filters State
    const [selectedShowFilter, setSelectedShowFilter] = React.useState<ShowFilterType>(
        ShowAllFilterType,
    );
    const showFilters = [
        {
            type: ShowAllFilterType,
            text: translate("Show All"),
        },
        {
            type: ShowAssignedToUserFilterType,
            text: translate("Show assigned to this user"),
        },
        {
            type: ShowUnassigned,
            text: translate("Show unassigned to any user"),
        },
    ];

    const handleShowFilterChanged = (data: Partial<{ value: ShowFilterType }>) => {
        setSelectedShowFilter(data.value || ShowAllFilterType);
    };

    React.useEffect(() => {
        // Fetch Context for users Tenant and Application
        if (!user.tenantId || applicationId === "") {
            return;
        }
        accountServiceContext.contexts
            .load({
                filter: [
                    ["tenantId", "=", user.tenantId],
                    "and",
                    ["tenantApplication.applicationId", "=", new Guid(applicationId)],
                ],
            })
            .then((contexts) => {
                setContexts(contexts);

                if (contexts.length === 1) {
                    // If only one, can auto select it for user.
                    setSelectedContext(contexts[0]);
                }
            });
    }, [applicationId, user]);

    React.useEffect(() => {
        // Update Grid DataSource filter based on changes
        if (!selectedContext || !user) {
            return;
        }

        switch (selectedShowFilter) {
            case ShowAllFilterType:
                setDataSourceOptions((prevState) => ({
                    ...prevState,
                    filter: [
                        ["contextId", "=", selectedContext.id],
                        "and",
                        ...buildValueSearchFilter(searchFilter),
                    ],
                }));
                break;
            case ShowAssignedToUserFilterType:
                setDataSourceOptions((prevState) => ({
                    ...prevState,
                    filter: [
                        ["contextId", "=", selectedContext.id],
                        "and",
                        [`userRoleContextValues/any(o:o/userRole/userId eq ${user.id})`],
                        "and",
                        ...buildValueSearchFilter(searchFilter),
                    ],
                }));
                break;
            case ShowUnassigned:
                setDataSourceOptions((prevState) => ({
                    ...prevState,
                    filter: [
                        ["contextId", "=", selectedContext.id],
                        "and",
                        ["not userRoleContextValues/any()"],
                        "and",
                        ...buildValueSearchFilter(searchFilter),
                    ],
                }));
                break;

            default:
                break;
        }
    }, [user, userRoles, selectedContext, searchFilter, selectedShowFilter]);

    React.useEffect(() => {
        if (!selectedContext || !user || userRoles.length > 0) {
            return;
        }

        setUserRoles(user.userRoles.reduce<IRole[]>((acc, curr) => {
            if ((!curr.role?.ignoreContext) &&
                 curr.role?.rolePermissions?.some((roleperm) => roleperm.permission.isContextAware === true)) {
                return [...acc, curr.role!];
            }
            else {
                return [...acc];
            }
        }, []));

    }, [selectedContext, user]);

    React.useEffect(() => {
        // Fetch Users Role Context Values
        if (!user || !selectedContext) {
            return;
        }
        accountServiceContext.userRoles
            .load({
                filter: [
                    ["userId", "=", user.id],
                    "and",
                    [
                        `userRoleContextValues/any(o:o/contextValue/contextId eq ${selectedContext.id})`,
                    ],
                ],
                expand: ["userRoleContextValues"],
            })
            .then((userRoles) => {
                // Consolidate the userRoleContextValues to make them easier to work with.
                setUserRoleContextValues(
                    userRoles.reduce<IUserRoleContextValue[]>(
                        (accumulator, currentValue) => [
                            ...accumulator,
                            ...(currentValue.userRoleContextValues || []),
                        ],
                        [],
                    ),
                );
                setDataSourceOptions((prevState) => ({
                    ...prevState,
                }));
            });
    }, [user, selectedContext]);

    const getSelectedRoleByContextForUser = (contextValueId: Guid, roles: IRole[]) => {
        if (roles.length === 0) {
            return undefined;
        }

        const foundItem = userRoleContextValues.find(
            (userRoleContextValue) =>
                userRoleContextValue.contextValueId.toString() === contextValueId.toString(),
        );

        if (foundItem) {
            const userRole = user.userRoles.find(
                (userRole) => userRole.id.toString() === foundItem.userRoleId.toString(),
            );

            if (userRole) {
                return userRole.roleId.toString();
            }
        }

        // User does not have a role for for the contextValueId
        return undefined;
    };

    // Context Select Changed handler
    const handleSelectedChanged = (data: Partial<{ value: Guid }>) => {
        if (data.value) {
            setSelectedContext(
                contexts.find((context) => context.id.toString() === data.value!.toString()),
            );
        }
    };

    // Component to Render the Selection Cell for a Context Value
    const ContextSelectionCell = ({
        data,
    }: {
        data: { key: Guid; value: string; data: IContextValue };
    }) => {
        const contextValueId = data.data.id.toString();
        const selected =
            userRoleContextValues.find((a) => a.contextValueId.toString() === contextValueId) !==
            undefined;
        const handleValueChanged = (checkboxData: Partial<{ value: boolean }>) => {
            const roleId = userRoles[0].id;
            const userRoleId = user.userRoles.find((a) => a.roleId.toString() === roleId.toString())
                ?.id;
            const contextValueId = data.data.id;

            if (checkboxData.value) {
                // Add to list
                if (!userRoleId) {
                    // For some reason the user did not have the role to the contextValue
                    notify(
                        translate("User does not have current Role, please try again."),
                        "error",
                    );

                    return;
                }

                const userRoleContextValue = {
                    id: NEW_GUID_ID,
                    userRoleId,
                    contextValueId,
                };
                onUserRoleContextValuesChanged([{ type: "ADD", value: userRoleContextValue }]);
                setUserRoleContextValues([...userRoleContextValues, userRoleContextValue]);
            } else {
                // Remove from list
                const userRoleContextValue = userRoleContextValues.find(
                    (a) => a.contextValueId.toString() === contextValueId.toString(),
                );

                if (!userRoleContextValue) {
                    // The user does not have the context value, just warn user.
                    notify(
                        translate("User was not part of this context, please try again."),
                        "warning",
                    );

                    return;
                }
                onUserRoleContextValuesChanged([{ type: "REMOVE", value: userRoleContextValue }]);
                setUserRoleContextValues([
                    ...userRoleContextValues.filter(
                        (a) => a.contextValueId.toString() !== contextValueId.toString(),
                    ),
                ]);
            }
            setDataSourceOptions({
                ...dataSourceOptions,
            });
        };

        return (
            <div>
                <CheckBox
                    text={data.data.value}
                    value={selected}
                    onValueChanged={handleValueChanged}
                />
            </div>
        );
    };

    // Render Component for Role Radio Button Selection
    const RoleSelectionCell = ({
        data,
    }: {
        data: { key: Guid; value: string; data: IContextValue };
    }) => {
        const contextValueId = data.data.id;

        const handleOptionChanged = (data: Partial<{ value: Guid }>) => {
            const roleId = data.value;

            if (!roleId) {
                return;
            }

            const userRole = user.userRoles.find((a) => a.roleId.toString() === roleId.toString());
            const userRoleContextValue: IUserRoleContextValue = {
                id: NEW_GUID_ID,
                userRoleId: userRole!.id,
                contextValueId,
            };

            if (!userRole) {
                notify(
                    translate("User does not have current Role, please refresh and try again."),
                    "error",
                );

                return;
            }

            const userRoleContextValuesToRemove = userRoleContextValues.filter(
                (a) => a.contextValueId.toString() === contextValueId.toString(),
            );
            let newList = [...userRoleContextValues];
            const userRoleContextValuesChanged: IUserRoleContextValueChange[] = [];

            const areNotUserRoleContextValue = (
                value1: IUserRoleContextValue,
                value2: IUserRoleContextValue,
            ) =>
                !(
                    value1.contextValueId.toString() === value2.contextValueId.toString() &&
                    value1.userRoleId.toString() === value2.userRoleId.toString()
                );

            for (const itemToRemove of userRoleContextValuesToRemove) {
                userRoleContextValuesChanged.push({ type: "REMOVE", value: itemToRemove });
                newList = [...newList.filter((a) => areNotUserRoleContextValue(a, itemToRemove))];
            }

            onUserRoleContextValuesChanged([
                ...userRoleContextValuesChanged,
                { type: "ADD", value: userRoleContextValue },
            ]);
            setUserRoleContextValues([...newList, userRoleContextValue]);
            setDataSourceOptions({
                ...dataSourceOptions,
            });
        };

        return (
            <div>
                <RadioGroup
                    displayExpr="name"
                    items={userRoles}
                    layout="horizontal"
                    value={getSelectedRoleByContextForUser(contextValueId, userRoles)}
                    valueExpr="id"
                    onValueChanged={handleOptionChanged}
                />
            </div>
        );
    };

    return (
        <div
            key={applicationId}
            className="user-edit-application-contexts"
            data-test-selector="user-edit-application-contexts"
        >
            {contexts.length > 1 && (
                <SelectBox
                    displayExpr="name"
                    items={contexts}
                    label={translate("Selected Context")}
                    valueExpr="id"
                    required
                    onValueChanged={handleSelectedChanged}
                />
            )}

            {selectedContext && (
                <>
                    <div className="assigned --bold">
                        {translate(
                            "{0} {1} Assigned",
                            userRoleContextValues.length.toString(),
                            userRoleContextValues.length > 1
                                ? selectedContext.name + "s"
                                : selectedContext.name,
                        )}
                    </div>
                    <div className="search-filters">
                        <TextBox
                            className="text-box"
                            label={translate("Search")}
                            placeholder={translate("Search {0}", selectedContext.name)}
                            value={searchFilter}
                            hideLabel
                            onChanged={setSearchFilter}
                        />
                        <RadioGroup
                            displayExpr="text"
                            items={showFilters}
                            layout="horizontal"
                            value={selectedShowFilter}
                            valueExpr="type"
                            onValueChanged={handleShowFilterChanged}
                        />
                    </div>
                    <DataGrid dataSource={dataSourceOptions}>
                        <Paging defaultPageSize={25} />
                        <Pager
                            allowedPageSizes={[25, 50, 100]}
                            showInfo
                            showPageSizeSelector
                            visible
                        />
                        <Column
                            caption={selectedContext.name}
                            cellComponent={ContextSelectionCell}
                            dataField="value"
                        />
                        {userRoles.length > 1 && (
                            <Column
                                caption={translate("Role")}
                                cellComponent={RoleSelectionCell}
                                dataField="id"
                            />
                        )}
                    </DataGrid>
                </>
            )}
        </div>
    );
};

export default UserEditApplicationContexts;
