import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext, {getTenantsDataSource} from "../../odata/accountServiceContext";

export default class UserRoleContextValuesTemplate extends React.Component {
    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.userRoleContextValuesDataSource = getUserRoleContextValuesDataSource(props.data.key);
            this.contextsDataSource = getContextsDataSource(props.data.data.tenantId, props.data.data.roleId);
            this.contextValuesDataSource = getContextValuesDataSource(props.data.data.tenantId, props.data.data.roleId);
            this.tenantsDataSource = getTenantsDataSource();
        }
    }

    componentDidMount() {
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.tenantId = this.props.data.data.tenantId;
        e.data.userRoleId = this.props.data.key;
    };

    onRowInserting = (e) => {
        delete e.data.contextValue;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.userRoleContextValuesDataSource}
                popupTile="User Role Context Value Info"
                onInitNewRow={this.onInitNewRow}
                onRowInserting={this.onRowInserting}>

                {/* Grid Columns */}
                <Column dataField="tenantId" dataType="Guid" caption="Tenant" allowEditing={false} visible={false}>
                    <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="contextValue.contextId" caption="Context">
                    <Lookup dataSource={this.contextsDataSource} displayExpr="name" valueExpr="id"/>
                </Column>
                <Column dataField="contextValueId" dataType="Guid" caption="Context Value">
                    <Lookup dataSource={this.contextValuesDataSource} displayExpr="value" valueExpr="id"/>
                </Column>
                <Column dataField="contextValue.value" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId", "contextValue.contextId", "contextValueId"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2} tabs={[{title: "Audit Info", colCount: 2, items: ["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}]}/>

            </BaseDataGrid>
        );
    }
}

function getUserRoleContextValuesDataSource(userRoleId) {
    return {
        store: accountServiceContext.userRoleContextValues,
        filter: ['userRoleId', '=', userRoleId],
        expand: 'contextValue.context',
        sort: 'contextValue.value'
    };
}

function getContextsDataSource(tenantId, roleId) {
    return {
        store: accountServiceContext.contexts,
        filter: [
            ["tenantId", "=", tenantId],
            "and",
            [`rolecontexts/any(o:o/roleid eq ${roleId})`]
        ],
        select: ['id', 'name'],
        sort: 'name'
    };
}

function getContextValuesDataSource(tenantId, roleId) {
    return {
        store: accountServiceContext.contextValues,
        filter: [
            ["tenantId", "=", tenantId],
            "and",
            [`context/rolecontexts/any(o:o/roleid eq ${roleId})`]
        ],
        select: ['id', 'value', 'context.id'],
        sort: 'value'
    };
}
