import React from "react";
import DXRadioGroup, { IRadioGroupOptions } from "devextreme-react/radio-group";
import "./RadioGroup.tsx.scss";

type Props = IRadioGroupOptions;

const RadioGroup: React.FC<Props> = (props) => {
    return (
        <div className="radio-group">
            <DXRadioGroup {...props} />
        </div>
    );
};

export default RadioGroup;
