let OIDC_AUTHORITY_URL;
let OIDC_CLIENT_ID;
let OIDC_RESPONSE_TYPE;
let OIDC_SCOPE;
let OIDC_METADATA_URL;
let SPA_URL;

export function setConfiguration(configuartion) {
    OIDC_AUTHORITY_URL = configuartion.oidcAuthorityUrl;
    OIDC_CLIENT_ID = configuartion.oidcClientId;
    OIDC_RESPONSE_TYPE = configuartion.oidcResponseType;
    OIDC_SCOPE = configuartion.oidcScope;
    OIDC_METADATA_URL = configuartion.oidcMetadataUrl;
    SPA_URL = configuartion.spaUrl;

    IDENTITY_CONFIG.authority = OIDC_AUTHORITY_URL;
    IDENTITY_CONFIG.client_id = OIDC_CLIENT_ID;
    IDENTITY_CONFIG.redirect_uri = `${SPA_URL}/signin-oidc`;
    IDENTITY_CONFIG.silent_redirect_uri = `${SPA_URL}/silent-renew`;
    IDENTITY_CONFIG.post_logout_redirect_uri = `${SPA_URL}/signout-oidc`;
    IDENTITY_CONFIG.response_type = OIDC_RESPONSE_TYPE;
    IDENTITY_CONFIG.scope = OIDC_SCOPE;

    METADATA_URL = OIDC_METADATA_URL;
}

export const IDENTITY_CONFIG = {
    authority: "",
    client_id: "",
    redirect_uri: "",
    silent_redirect_uri: "",
    post_logout_redirect_uri: "",
    response_type: "",
    automaticSilentRenew: true,
    loadUserInfo: false,
    scope: ""
};

export let METADATA_URL = "";
