import React from "react";

import uuid from "../../core/UUID";
import "./TextBox.tsx.scss";

interface Props
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    id?: string;
    className?: string;
    label: string;
    value: string;
    required?: boolean;
    disabled?: boolean;
    hideLabel?: boolean;
    errorMessage?: string;
    onChanged: (newValue: string) => void;
}

const TextBox: React.FC<Props> = ({
    id,
    className = "",
    label,
    hideLabel,
    value,
    required,
    disabled,
    errorMessage,
    onChanged,
    ...otherProps
}) => {
    const [inputId] = React.useState(id || uuid());

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChanged(event.target.value || "");
    };

    return (
        <div className={`text-box ${className}`}>
            <label className={`label --bold ${hideLabel && "--sr-only"}`} htmlFor={inputId}>
                {label}
                {required && <span className="--required"> *</span>}
            </label>
            <input
                aria-invalid={errorMessage ? !!errorMessage : undefined}
                aria-required={!disabled && required}
                className="value"
                disabled={disabled}
                id={inputId}
                required={required}
                value={value}
                onChange={handleChange}
                {...otherProps}
            />
            {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
    );
};

export default TextBox;
