import React, { Component } from "react";
import AuthService from "./authService";

export const AuthContext = React.createContext({
    signinRedirectCallback: () => ({}),
    logout: () => ({}),
    signoutRedirectCallback: () => ({}),
    isAuthenticated: () => ({}),
    signinRedirect: () => ({}),
    signinSilentCallback: () => ({}),
    createSigninRequest: () => ({})
});

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
    authService;
    constructor(props) {
        super(props);
        this.authService = new AuthService();

        this.clearSessionListener = this.clearSessionListener.bind(this);
    }

    clearSessionListener(event) {
        if (event.data === "clearSessionStorage") {
            console.log(`Received message from ${event.origin} to clear session storage.`);
            this.authService.clearSessionStorage();

            event.source.postMessage("sessionStorageCleared", event.origin);
        }
    }

    componentDidMount() {
        window.addEventListener("message", this.clearSessionListener);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.clearSessionListener);
    }
    render() {
        return <AuthContext.Provider value={this.authService}>{this.props.children}</AuthContext.Provider>;
    }
}
