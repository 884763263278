import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import {setConfiguration} from './auth/authConstants';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

async function getConfigurationAsync() {
    let response = await fetch("/api/configuration");
    return await response.json();
}

getConfigurationAsync().then(configuration => {
    setConfiguration(configuration);
    
    ReactDOM.render(
        <BrowserRouter basename={baseUrl}>
            <App/>
        </BrowserRouter>,
        rootElement);
});
