import React from "react";

import { AuthConsumer } from "../../auth/authProvider";

const SilentRenew = () => (
    <AuthConsumer>
        {({ signinSilentCallback }) => {
            signinSilentCallback();
            return <span>loading</span>;
        }}
    </AuthConsumer>
);

export default SilentRenew;
