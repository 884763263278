interface UserSettings {
    search: { [key: string]: SearchSettings };
}

export interface SearchSettings {
    filter?: string;
    gridState?: unknown;
}

export const DEFAULT_SEARCH_SETTINGS: SearchSettings = {};

let _userSettings: UserSettings | undefined = undefined;
const getUserSettings = () => {
    if (!_userSettings) {
        _userSettings = {
            search: {
                userList: DEFAULT_SEARCH_SETTINGS,
            },
        };
    }

    return _userSettings;
};

export const useUserSettings = (): UserSettings => getUserSettings();

export const setUserSearchSettings = (key: string, userSearchSettings: SearchSettings) => {
    const userSettings = getUserSettings();
    userSettings.search[key] = userSearchSettings;
    _userSettings = userSettings;
};

export default useUserSettings;
