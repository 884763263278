import React from "react";
import DxSelectBox, { ISelectBoxOptions } from "devextreme-react/ui/select-box";

import uuid from "../../core/UUID";
import "./SelectBox.tsx.scss";

interface Props extends ISelectBoxOptions {
    label: string;
    required?: boolean;
    hideLabel?: boolean;
}

const SelectBox: React.FC<Props> = ({ id, label, hideLabel, required, ...otherProps }) => {
    const [inputId] = React.useState(id || uuid());

    return (
        <div className="select-box">
            <label className={`label --bold ${hideLabel && "--sr-only"}`} htmlFor={inputId}>
                {label}
                {required && <span className="--required"> *</span>}
            </label>
            <DxSelectBox id={inputId} {...otherProps} />
        </div>
    );
};

export default SelectBox;
