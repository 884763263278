import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext, {getTenantsDataSource} from "../../odata/accountServiceContext";
import TenantContext from "./TenantContext";

export default class TenantApplicationRoleContextValuesTemplate extends React.Component {
    static contextType = TenantContext;

    constructor(props) {
        super(props);

        if (!props.data.row.isNewRow) {
            this.roleContextValuesDataSource = getRoleContextValuesDataSource(props.data.key);
            this.contextsDataSource = getContextsDataSource(props.data.data.contextId);
            this.contextValuesDataSource = getContextValuesDataSource(props.data.data.contextId);
            this.tenantsDataSource = getTenantsDataSource();
        }
    }

    componentDidMount() {
        this.tenantId = this.context.tenantId;
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.tenantId = this.tenantId;
        e.data.roleContextId = this.props.data.key;
        e.data.contextValue = {contextId: this.props.data.data.contextId}
    };

    onRowInserting = (e) => {
        delete e.data.contextValue;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.roleContextValuesDataSource}
                popupTitle="Role Context Values Info"
                onInitNewRow={this.onInitNewRow}
                onRowInserting={this.onRowInserting}>

                {/* Grid Columns */}
                <Column dataField="tenantId" dataType="Guid" caption="Tenant" allowEditing={false} visible={false}>
                    <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="contextValue.contextId" allowEditing={false} caption="Context">
                    <Lookup dataSource={this.contextsDataSource} displayExpr="name" valueExpr="id"/>
                </Column>
                <Column dataField="contextValueId" dataType="Guid" caption="Context Value">
                    <Lookup dataSource={this.contextValuesDataSource} displayExpr="value" valueExpr="id"/>
                </Column>
                <Column dataField="contextValue.value" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId", "contextValue.contextId", "contextValueId"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2} tabs={[{title: "Audit Info", colCount: 2, items: ["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}]}/>

            </BaseDataGrid>
        );
    }
}

function getRoleContextValuesDataSource(roleContextId) {
    return {
        store: accountServiceContext.roleContextValues,
        filter: ['roleContextId', '=', roleContextId],
        expand: 'contextValue.context',
        sort: 'contextValue.value'
    };
}

function getContextsDataSource(contextId) {
    return {
        store: accountServiceContext.contexts,
        filter: ["id", "=", contextId],
        select: ['id', 'name'],
        sort: 'name'
    };
}

function getContextValuesDataSource(contextId) {
    return {
        store: accountServiceContext.contextValues,
        filter: ["context.id", "=", contextId],
        select: ['id', 'value', 'context.id'],
        sort: 'value'
    };
}
