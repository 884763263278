import React from 'react';

import {Column} from 'devextreme-react/data-grid';
import {Item, Tab} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext from "../../odata/accountServiceContext";
import ApplicationEnvironmentsTemplate from "./ApplicationEnvironmentsTemplate";

export default class ApplicationManagement extends React.Component {
    render() {
        return (
            <BaseDataGrid
                dataSource={{ store: accountServiceContext.applications, sort: 'name'}}
                popupTitle="Application Info">

                {/* Grid Columns */}
                <Column dataField="name" caption="Name"/>
                <Column dataField="description" caption="Description"/>
                <Column dataField="turnstileProductId" caption="Turnstile Product Id"/>
                <Column dataField="isSystemApplication" caption="System Application"/>
                <Column dataField="requiresProvisioning" caption="Requires Provisioning"/>
                <Column dataField="requiresProvisioning" caption="Requires Provisioning"/>
                <Column dataField="tenantPerInstance" caption="Tenant Per Instance"/>
                <Column dataField="allowsCustomPermissions" caption="Allows Custom Permissions" visible={false}/>
                <Column dataField="iconImagePath" caption="Icon Image Path" visible={false}/>
                <Column dataField="applicationEnvironments" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false} editCellComponent={ApplicationEnvironmentsTemplate}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2}
                      items={["id", "turnstileProductId", "name", "description", "isSystemApplication", "requiresProvisioning", "allowsCustomPermissions", "tenantPerInstance", "iconImagePath"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2}>
                    <Tab title="Environments" items={[{dataField: "applicationEnvironments", label: {visible: false}}]}/>
                    <Tab title="Audit Info" colCount={2} items={["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}/>
                </Item>

            </BaseDataGrid>
        );
    }
}
