import React from "react";
import "./Checkbox.tsx.scss";
import DXCheckBox, { ICheckBoxOptions } from "devextreme-react/ui/check-box";

type Props = ICheckBoxOptions;

const CheckBox: React.FC<Props> = (props) => {
    return <DXCheckBox className="checkbox" {...props} />;
};

export default CheckBox;
