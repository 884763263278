import * as React from "react";

import { AuthContext } from "../../auth/authProvider";

/**
 * This component should be on an non-authenticated route.
 * It can then be used to better time events during automation.
 **/
const SigninStatus = () => {
    const auth = React.useContext(AuthContext);
    const handleLogin = () => {
        auth.signinRedirect();
    };
    return (
        <button data-test-selector="signin-status" onClick={handleLogin}>{`${auth.isAuthenticated()}`}</button>
    );
}

export default SigninStatus;
