const translationMap: { [key:string]: string} = {
};

const translate = (text:string, ...replacementValues: string[]) => {
    if (!text) {
        return "";
    }
    if (translationMap[text]) {
        text = translationMap[text];
    }

    return replacer(text, replacementValues);
};

const replacer = (text: string, replacementValues: string[]) => {
    return replacementValues.reduce((accumulator, replacementValue, replacementIndex) => {
        return accumulator.replace(`{${replacementIndex}}`, replacementValue);
    }, text);
};

export default translate;
