import React, { Component } from 'react';
import {
    Collapse, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, NavLink,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import useCurrentUser from "../../user/useCurrentUser";
import './NavMenu.css';

export default class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.currentUser = useCurrentUser();
        this.epiUserDisplayName = this.currentUser && this.currentUser.profile ? this.currentUser.profile.epiUserDisplayName : "";
        this.preferredUserName = this.currentUser && this.currentUser.profile ? this.currentUser.profile.preferred_username : "";

        this.canViewUsers = this.currentUser && this.currentUser.permissions ? this.currentUser.permissions.canViewUsers : false; 
        this.canViewTenants = this.currentUser && this.currentUser.permissions ? this.currentUser.permissions.canViewTenants : false;
        this.canViewApplications = this.currentUser && this.currentUser.permissions ? this.currentUser.permissions.canViewApplications : false;
        this.canViewEnvironments = this.currentUser && this.currentUser.permissions ? this.currentUser.permissions.canViewEnvironments : false;
        this.canViewGroups = this.currentUser && this.currentUser.permissions ? this.currentUser.permissions.canViewGroups : false;

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <NavbarBrand tag={Link} to="/">Epi Identity</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <Nav className="ml-auto">
                            <UncontrolledDropdown>
                                <DropdownToggle className="text-dark" nav>
                                    <Avatar />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        <NavLink tag={Link} className="text-dark" to="/signout">Sign Out</NavLink>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem disabled>
                                        <NavLink>{this.epiUserDisplayName}</NavLink>
                                    </DropdownItem>
                                    <DropdownItem disabled>
                                        <NavLink>{this.preferredUserName}</NavLink>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                        <Nav className="mr-auto" navbar>
                            <NavItem>
                                <NavLink className={(this.canViewUsers) ? "text-dark" : ""} disabled={!this.canViewUsers} tag={Link} to="/">Users</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={(this.canViewTenants) ? "text-dark" : ""} disabled={!this.canViewTenants} tag={Link} to="/tenants">Tenants</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={(this.canViewApplications) ? "text-dark" : ""} disabled={!this.canViewApplications} tag={Link} to="/applications">Applications</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={(this.canViewEnvironments) ? "text-dark" : ""} disabled={!this.canViewEnvironments} tag={Link} to="/environments">Environments</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={(this.canViewGroups) ? "text-dark" : ""} disabled={!this.canViewGroups} tag={Link} to="/groups">Groups</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
