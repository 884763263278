import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext, {getTenantsDataSource} from "../../odata/accountServiceContext";
import Guid from "devextreme/core/guid";
import TenantContext from "./TenantContext";

export default class TenantApplicationRolePermissionsTemplate extends React.Component {
    static contextType = TenantContext;

    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.rolePermissionsDataSource = getRolePermissionsDataSource(props.data.data);
            this.permissionsDataSource = getPermissionsDataSource(this.props.data.data.tenantApplication);
            this.tenantsDataSource = getTenantsDataSource();
        }
    }

    componentDidMount() {
        this.tenantId = this.context.tenantId;
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.tenantId = this.tenantId;
        e.data.roleId = this.props.data.key;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.rolePermissionsDataSource}
                popupTitle="Role Permissions Info"
                onInitNewRow={this.onInitNewRow}>

                {/* Grid Columns */}
                <Column dataField="tenantId" dataType="Guid" caption="Tenant" allowEditing={false} visible={false}>
                    <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="permissionId" dataType="Guid" caption="Permission">
                    <Lookup dataSource={this.permissionsDataSource} displayExpr="name" valueExpr="id"/>
                </Column>
                <Column dataField="permission.name" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId"]}/>
                <Item itemType="group" colCount={1} colSpan={2} items={["permissionId"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2} tabs={[{title: "Audit Info", colCount: 2, items: ["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}]}/>

            </BaseDataGrid>
        );
    }
}

function getRolePermissionsDataSource(role) {
    return {
        store: accountServiceContext.rolePermissions,
        filter: [
            ['roleId', '=', role.id],
            "and",
            [
                ["permission.tenantApplicationId", "=", role.tenantApplicationId],
                "or",
                [
                    ["permission.tenantApplication.tenantId", "=", new Guid("00000000-0000-0000-0000-000000000000")],
                    "and",
                    ["permission.tenantApplication.applicationId", "=", role.tenantApplication.applicationId]
                ]
            ]
        ],
        sort: 'permission.name'
    };
}

function getPermissionsDataSource(tenantApplication) {
    return {
        store: accountServiceContext.permissions,
        select: ['id', 'name'],
        filter: [
            ['tenantApplicationId', '=', tenantApplication.id],
            "or",
            [
                ["tenantApplication.tenantId", "=", new Guid("00000000-0000-0000-0000-000000000000")],
                "and",
                ["tenantApplication.applicationId", "=", tenantApplication.applicationId]
            ]
        ],
        sort: 'name'
    };
}

