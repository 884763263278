import IUserRole from "../../../odata/entities/IUserRole";

const userRolesChanged = (userRoles: IUserRole[], userRoleIds: string[]) => {
    const userRolesFiltered = userRoles.filter((a) => !a.role?.isSystemRole);
    const deltaRoles = userRolesFiltered.filter((a) => userRoleIds.includes(a.roleId.toString()));

    if (
        userRolesFiltered.length !== deltaRoles.length ||
        userRoleIds.length !== deltaRoles.length
    ) {
        return true;
    }

    return false;
};

export default userRolesChanged;
