import React from 'react';

import DataGrid, {
    Column,
    Editing,
    Popup,
    Form,
    SearchPanel,
    FilterRow,
    Paging,
    ColumnChooser,
    Position
} from 'devextreme-react/data-grid';

const BaseDataGrid = React.forwardRef((props, ref) =>
    (<DataGrid
        ref={ref}
        dataSource={props.dataSource}
        showBorders
        allowColumnReordering
        allowColumnResizing
        columnResizingMode="widget"
        columnMinWidth={50}
        columnAutoWidth
        onEditorPreparing={props.onEditorPreparing}
        onEditingStart={props.onEditingStart}
        onInitNewRow={props.onInitNewRow}
        onRowInserting={props.onRowInserting}
        onRowInserted={props.onRowInserted}>
        <ColumnChooser enabled mode="select"/>
        <Paging enabled/>
        <SearchPanel visible/>
        <FilterRow visible/>
        <Editing
            mode="popup"
            useIcons
            allowAdding
            allowUpdating
            allowDeleting>
            <Popup title={props.popupTitle} showTitle maxWidth="1110px">
                <Position my="top" at="top" of={window}/>
            </Popup>
            <Form>
                {getComponents(props, "items")}
            </Form>
        </Editing>
        {getComponents(props, "columns")}
        <Column dataField="id" dataType="Guid" caption="Id" allowEditing={true} visible={false}/>
        <Column dataField="createdBy" caption="Created By" visible={false} allowEditing={false}/>
        <Column dataField="createdOn" caption="Created On" dataType="datetime" visible={false} allowEditing={false}/>
        <Column dataField="modifiedBy" caption="Modified By" visible={false} allowEditing={false}/>
        <Column dataField="modifiedOn" caption="Modified On" dataType="datetime" visible={false} allowEditing={false}/>
    </DataGrid>)
);

export default BaseDataGrid;

function getComponents(props, key) {
    return props.children.filter((child) => {
        return child.type.OptionName === key;
    });
}

