import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item, Tab} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext, {getTenantsDataSource} from "../../odata/accountServiceContext";
import TenantApplicationSettingsTemplate from "./TenantApplicationSettingsTemplate";
import TenantApplicationEnvironmentsTemplate from "./TenantApplicationEnvironmentsTemplate";
import TenantApplicationRolesTemplate from "./TenantApplicationRolesTemplate";
import TenantApplicationPermissionsTemplate from "./TenantApplicationPermissionsTemplate";
import TenantApplicationContextsTemplate from "./TenantApplicationContextsTemplate";
import TenantContext from "./TenantContext";

export default class TenantApplicationsTemplate extends React.Component {
    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.tenantApplicationsDataSource = getTenantApplicationsDataSource(props.data.key);
            this.applicationsDataSource = getApplicationsDataSource();
            this.tenantsDataSource = getTenantsDataSource();
        }
    }

    componentDidMount() {
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.tenantId = this.props.data.key;
    };

    render() {
        return (
            <TenantContext.Provider value={{tenantId: this.props.data.key}}>
                <BaseDataGrid
                    ref={(ref) => {
                        this.dataGrid = ref;
                    }}
                    dataSource={this.tenantApplicationsDataSource}
                    popupTile="Tenant Application Info"
                    onInitNewRow={this.onInitNewRow}>

                    {/* Grid Columns */}
                    <Column dataField="tenantId" dataType="Guid" caption="Tenant" allowEditing={false} visible={false}>
                        <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                    </Column>
                    <Column dataField="applicationId" dataType="Guid" caption="Application">
                        <Lookup dataSource={this.applicationsDataSource} displayExpr="name" valueExpr="id"/>
                    </Column>
                    <Column dataField="roles" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false} editCellComponent={TenantApplicationRolesTemplate}/>
                    <Column dataField="permissions" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false} editCellComponent={TenantApplicationPermissionsTemplate}/>
                    <Column dataField="contexts" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false} editCellComponent={TenantApplicationContextsTemplate}/>
                    <Column dataField="tenantApplicationSettings" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}
                            editCellComponent={TenantApplicationSettingsTemplate}/>
                    <Column dataField="tenantApplicationEnvironments" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}
                            editCellComponent={TenantApplicationEnvironmentsTemplate}/>
                    <Column dataField="application.name" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}/>

                    {/* Edit Form */}
                    <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId"]}/>
                    <Item itemType="group" colCount={1} colSpan={2} items={["applicationId"]}/>
                    <Item itemType="tabbed" colCount={1} colSpan={2}>
                        <Tab title="Roles" items={[{dataField: "roles", label: {visible: false}}]}/>
                        <Tab title="Permissions" items={[{dataField: "permissions", label: {visible: false}}]}/>
                        <Tab title="Contexts" items={[{dataField: "contexts", label: {visible: false}}]}/>
                        <Tab title="Environments" items={[{dataField: "tenantApplicationEnvironments", label: {visible: false}}]}/>
                        <Tab title="Application Settings" items={[{dataField: "tenantApplicationSettings", label: {visible: false}}]}/>
                        <Tab title="Audit Info" colCount={2} items={["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}/>
                    </Item>

                </BaseDataGrid>
            </TenantContext.Provider>
        );
    }
}

function getTenantApplicationsDataSource(tenantId) {
    return {
        store: accountServiceContext.tenantApplications,
        filter: ['tenantId', '=', tenantId],
        sort: 'application.name'
    };
}

function getApplicationsDataSource() {
    return {
        store: accountServiceContext.applications,
        select: ['id', 'name'],
        sort: 'name'
    };
}
