import React, { Component } from "react";
import { Route, Switch } from "react-router";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.carmine.compact.css";
import "./components/controls/Modal.scss";
import "./custom.css";

import { AuthProvider } from "./auth/authProvider";
import PrivateRoute from "./components/auth/PrivateRoute";
import Callback from "./components/auth/Callback";
import Logout from "./components/auth/Logout";
import LogoutCallback from "./components/auth/LogoutCallback";
import SilentRenew from "./components/auth/SilentRenew";
import Layout from "./components/pageLayout/Layout";
import IFrameLayout from "./components/pageLayout/IFrameLayout";
import UserManagement from "./components/users/UserManagement";
import TenantManagement from "./components/tenants/TenantManagement";
import ApplicationManagement from "./components/applications/ApplicationManagement";
import EnvironmentManagement from "./components/environments/EnvironmentManagement";
import GroupManagement from "./components/groups/GroupManagement";
import UserList, { UserListPath } from "./components/users/UserList";
import UserEdit from "./components/users/UserEdit";
import UserInvite, { UserInvitePath } from "./components/users/UserInvite";
import SigninStatus from "./components/auth/SigninStatus";

const IFrameWrapperLayout = () => (
    <IFrameLayout>
        <PrivateRoute component={UserList} path={UserListPath} exact />
        <PrivateRoute component={UserInvite} path={UserInvitePath} />
        <PrivateRoute component={UserEdit} path="/iframe/users/:userId/edit" />
    </IFrameLayout>
);

const MainLayout = () => (
    <Layout>
        <Route component={SigninStatus} path="/signin-status" exact />
        <Route component={Callback} path="/signin-oidc" exact />
        <Route component={Logout} path="/signout" exact />
        <Route component={LogoutCallback} path="/signout-oidc" exact />
        <Route component={SilentRenew} path="/silent-renew" exact />

        <PrivateRoute component={UserManagement} path="/" exact />
        <PrivateRoute component={UserManagement} path="/users" exact />
        <PrivateRoute component={TenantManagement} path="/tenants" exact />
        <PrivateRoute component={ApplicationManagement} path="/applications" exact />
        <PrivateRoute component={EnvironmentManagement} path="/environments" exact />
        <PrivateRoute component={GroupManagement} path="/groups" exact />
    </Layout>
);

// eslint-disable-next-line react/prefer-stateless-function
export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <AuthProvider>
                <Switch>
                    <Route component={IFrameWrapperLayout} path="/iframe" />
                    <Route component={MainLayout} path="/" />
                </Switch>
            </AuthProvider>
        );
    }
}
