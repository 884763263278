import IUser from "../../../odata/entities/IUser";
import accountServiceContext from "../../../odata/accountServiceContext";
import IInsertUser from "../models/IInsertUser";

const toUser = (user: IInsertUser, tenantId: string, groupId: string): Omit<IUser, 'id'> => ({
    ...user,
    tenantId,
    groupId,
});

/**
 * Insert user into account service, and assign the user to the roleIds passed in.
 * @param user The user to insert
 * @param tenantId The tenantId the user should be assigned to.
 * @param groupId The groupId the user should be assigned to.
 * @param roleIds The Roles the user should be assigned to.
 */
export const insertUser = async (user: IInsertUser, tenantId: string, groupId: string, roleIds: string[]): Promise<{ successful: boolean }> => {
    var insertedUser = await accountServiceContext.users.insert(
        toUser(user, tenantId, groupId)
    );
    // Assign Roles
    for (const roleId of roleIds) {
        await accountServiceContext.userRoles.insert({
            tenantId,
            userId: insertedUser.id,
            roleId,
        });
    }

    return { successful: true };
};

export default insertUser;
