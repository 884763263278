import ODataContext from "devextreme/data/odata/context";
import Guid from "devextreme/core/guid";

import { IDENTITY_CONFIG } from "../auth/authConstants";

import { ODataStore } from "./devxtream.odata.overrides";
import ITenantApplication from "./entities/ITenantApplication";
import ITenant from "./entities/ITenant";
import IUser from "./entities/IUser";
import IUserRole from "./entities/IUserRole";
import IGroup from "./entities/IGroup";
import IContext from "./entities/IContext";
import IContextValue from "./entities/IContextValue";
import IRoleContext from "./entities/IRoleContext";
import IUserRoleContextValue from "./entities/IUserRoleContextValue";
import IRole from "./entities/IRole";

export const DEFAULT_SYSTEM_GUID = new Guid("00000000-0000-0000-0000-000000000000");

/**
 * Better typing of the ODataStore and ODataContext than provided by DevExpress.
 */
export type IAccountServiceContext = {
    contexts: ODataStore<IContext>;
    contextValues: ODataStore<IContextValue>;
    groups: ODataStore<IGroup>;
    roleContexts: ODataStore<IRoleContext>;
    tenantApplications: ODataStore<ITenantApplication>;
    users: ODataStore<IUser>;
    roles: ODataStore<IRole>;
    userRoles: ODataStore<IUserRole>;
    userRoleContextValues: ODataStore<IUserRoleContextValue>;
    tenants: ODataStore<ITenant>;
} & ODataContext;

const accountServiceContext: IAccountServiceContext = new ODataContext({
    url: "/odata/",
    version: 4,
    entities: {
        environments: {
            key: "id",
            keyType: "Guid",
        },
        applications: {
            key: "id",
            keyType: "Guid",
        },
        permissions: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                tenantApplicationId: "Guid",
            },
        },
        roles: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                tenantApplicationId: "Guid",
            },
        },
        rolePermissions: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                roleId: "Guid",
            },
        },
        roleContexts: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                roleId: "Guid",
            },
        },
        roleContextValues: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                roleContextId: "Guid",
            },
        },
        contexts: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                tenantApplicationId: "Guid",
            },
        },
        contextValues: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                contextId: "Guid",
            },
        },
        applicationEnvironments: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                applicationId: "Guid",
            },
        },
        tenants: {
            key: "id",
            keyType: "Guid",
        },
        tenantApplications: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                tenantId: "Guid",
            },
        },
        tenantApplicationSettings: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                tenantId: "Guid",
                tenantApplicationId: "Guid",
            },
        },
        tenantApplicationEnvironments: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                tenantId: "Guid",
                tenantApplicationId: "Guid",
            },
        },
        groups: {
            key: "id",
            keyType: "Guid",
        },
        users: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                tenantId: "Guid",
            },
        },
        userRoles: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                tenantId: "Guid",
                userId: "Guid",
                roleId: "Guid",
            },
        },
        userRoleContextValues: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                tenantId: "Guid",
                userRoleId: "Guid",
            },
        },
        userTenants: {
            key: "id",
            keyType: "Guid",
            fieldTypes: {
                tenantId: "Guid",
                userId: "Guid",
            },
        },
    },
    beforeSend: (e) => {
        const user = JSON.parse(
            sessionStorage.getItem(
                `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`,
            ) ?? "{}",
        );

        if (user && user.access_token) {
            e.headers = { Authorization: "Bearer " + user.access_token };
        }
    },
}) as IAccountServiceContext;

export default accountServiceContext;

export function getTenantsDataSource(excludeSystemTenant = false) {
    const filter = excludeSystemTenant
        ? ["!", ["id", "=", "00000000-0000-0000-0000-000000000000"]]
        : null;

    return {
        store: accountServiceContext.tenants,
        select: ["id", "companyName"],
        filter,
        sort: "companyName",
    };
}
