import React from 'react';

import {Column} from 'devextreme-react/data-grid';
import {Item, Tab} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext from "../../odata/accountServiceContext";
import TenantApplicationsTemplate from "./TenantApplicationsTemplate";

export default class TenantManagement extends React.Component {
    render() {
        return (
            <BaseDataGrid
                dataSource={{store: accountServiceContext.tenants, sort: 'companyName'}}
                popupTitle="Tenant Info">

                {/* Grid Columns */}
                <Column dataField="companyName" caption="Company Name"/>
                <Column dataField="commonName" caption="Common Name"/>
                <Column dataField="emergencyContactName" caption="Contact Name" visible={false}/>
                <Column dataField="emergencyContactPhone" caption="Contact Phone" visible={false}/>
                <Column dataField="emergencyContactEmail" caption="Contact Email" visible={false}/>
                <Column dataField="organizationId" caption="Organization Id" visible={false}/>
                <Column dataField="idpTenantId" caption="SSO Idp Tenant Id" visible={false}/>
                <Column dataField="clientId" caption="M2M SSO Client Id" visible={false}/>
                <Column dataField="deactivateOn" caption="Deactivate On" dataType="datetime"/>
                <Column dataField="tenantApplications" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false} editCellComponent={TenantApplicationsTemplate}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2}
                      items={["id", "companyName", "commonName", "emergencyContactName", "emergencyContactPhone", "emergencyContactEmail", "organizationId", "idpTenantId", "clientId", "deactivateOn"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2}>
                    <Tab title="Applications" items={[{dataField: "tenantApplications", label: {visible: false}}]}/>
                    <Tab title="Audit Info" colCount={2} items={["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}/>
                </Item>

            </BaseDataGrid>
        );
    }
}
