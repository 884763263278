import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext, {getTenantsDataSource} from "../../odata/accountServiceContext";
import Guid from "devextreme/core/guid";
import TenantContext from "./TenantContext";

export default class TenantApplicationPermissionsTemplate extends React.Component {
    static contextType = TenantContext;

    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.permissionsDataSource = getPermissionsDataSource(props.data.data);
            this.tenantsDataSource = getTenantsDataSource();
        }
    }

    componentDidMount() {
        this.tenantId = this.context.tenantId;
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.tenantId = this.tenantId;
        e.data.tenantApplicationId = this.props.data.key;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.permissionsDataSource}
                popupTitle="Application Permissions Info"
                onInitNewRow={this.onInitNewRow}>

                {/* Grid Columns */}
                <Column dataField="tenantId" dataType="Guid" caption="Tenant" allowEditing={false} visible={false}>
                    <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="name"/>
                <Column dataField="description"/>
                <Column dataField="isSystemPermission" dataType="boolean"/>
                <Column dataField="isContextAware" dataType="boolean"/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId", "name", "description", "isSystemPermission", "isContextAware"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2} tabs={[{title: "Audit Info", colCount: 2, items: ["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}]}/>

            </BaseDataGrid>
        );
    }
}

function getPermissionsDataSource(tenantApplication) {
    return {
        store: accountServiceContext.permissions,
        filter: [
            ['tenantApplicationId', '=', tenantApplication.id],
            "or",
            [
                ["tenantApplication.tenantId", "=", new Guid("00000000-0000-0000-0000-000000000000")],
                "and",
                ["tenantApplication.applicationId", "=", tenantApplication.applicationId]
            ]
        ],
        sort: 'name'
    };
}

