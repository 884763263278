import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item, Tab} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext, {getTenantsDataSource} from "../../odata/accountServiceContext";
import TenantApplicationContextValuesTemplate from "./TenantApplicationContextValuesTemplate";
import TenantContext from "./TenantContext";

export default class TenantApplicationContextsTemplate extends React.Component {
    static contextType = TenantContext;

    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.dataSource = getContexts(props.data.key);
            this.tenantsDataSource = getTenantsDataSource();
        }
    }

    componentDidMount() {
        this.tenantId = this.context.tenantId;
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.tenantId = this.tenantId;
        e.data.tenantApplicationId = this.props.data.key;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.dataSource}
                popupTitle="Context Info"
                onInitNewRow={this.onInitNewRow}>

                {/* Grid Columns */}
                <Column dataField="tenantId" dataType="Guid" caption="Tenant" allowEditing={false} visible={false}>
                    <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="name"/>
                <Column dataField="description"/>
                <Column dataField="contextValues" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false} editCellComponent={TenantApplicationContextValuesTemplate}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId", "name", "description"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2}>
                    <Tab title="Context Values" items={[{dataField: "contextValues", label: {visible: false}}]}/>
                    <Tab title="Audit Info" colCount={2} items={["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}/>
                </Item>
            </BaseDataGrid>
        );
    }
}

function getContexts(tenantApplicationId) {
    return {
        store: accountServiceContext.contexts,
        filter: ['tenantApplicationId', '=', tenantApplicationId],
        sort: 'name'
    };
}

