import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext, {getTenantsDataSource} from "../../odata/accountServiceContext";
import TenantContext from "./TenantContext";

export default class TenantApplicationContextValueValuesTemplate extends React.Component {
    static contextType = TenantContext;
    
    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.dataSource = getContextValues(props.data.key);
            this.tenantsDataSource = getTenantsDataSource();
        }
    }

    componentDidMount() {
        this.tenantId = this.context.tenantId;
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.tenantId = this.tenantId;
        e.data.contextId = this.props.data.key;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.dataSource}
                popupTitle="Context Value Info"
                onInitNewRow={this.onInitNewRow}>

                {/* Grid Columns */}
                <Column dataField="tenantId" dataType="Guid" caption="Tenant" allowEditing={false} visible={false}>
                    <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="value"/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "tenantId"]}/>
                <Item itemType="group" colCount={1} colSpan={2} items={["value"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2} tabs={[{title: "Audit Info", colCount: 2, items: ["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}]}/>

            </BaseDataGrid>
        );
    }
}

function getContextValues(contextId) {
    return {
        store: accountServiceContext.contextValues,
        filter: ['contextId', '=', contextId],
        sort: 'value'
    };
}

