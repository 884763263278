import React from 'react';

import {Column, Lookup} from 'devextreme-react/data-grid';
import {Item} from 'devextreme-react/form';
import BaseDataGrid from "../shared/BaseDataGrid";
import accountServiceContext, {getTenantsDataSource} from "../../odata/accountServiceContext";

export default class UserTenantsTemplate extends React.Component {
    constructor(props) {
        super(props);
        if (!props.data.row.isNewRow) {
            this.tenantId = props.data.data.tenantId;
            this.userTenantsDataSource = getUserTenantsDataSource(props.data.key);
            this.tenantsDataSource = getTenantsDataSource();
            this.tenantsWithoutSystemTenantDataSource = getTenantsDataSource(true);
        }
    }

    componentDidMount() {
        if (this.dataGrid && this.props.data.row.isNewRow) {
            this.dataGrid.instance.option({editing: {allowAdding: false}});
        }
    }

    onInitNewRow = (e) => {
        e.data.userId = this.props.data.key;
        e.data.user = { tenantId: this.tenantId };
    };

    onRowInserting = (e) => {
        delete e.data.user;
    };

    render() {
        return (
            <BaseDataGrid
                ref={(ref) => {
                    this.dataGrid = ref;
                }}
                dataSource={this.userTenantsDataSource}
                popupTitle="User Tenant Info"
                onInitNewRow={this.onInitNewRow}
                onRowInserting={this.onRowInserting}>

                {/* Grid Columns */}
                <Column dataField="user.tenantId" dataType="Guid" caption="Tenant" allowEditing={false} visible={false}>
                    <Lookup dataSource={this.tenantsDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="tenantId" dataType="Guid" caption="Assigned Tenant">
                    <Lookup dataSource={this.tenantsWithoutSystemTenantDataSource} displayExpr="companyName" valueExpr="id"/>
                </Column>
                <Column dataField="tenant.companyName" visible={false} allowEditing={false} allowSearch={false} showInColumnChooser={false}/>

                {/* Edit Form */}
                <Item itemType="group" colCount={2} colSpan={2} items={["id", "user.tenantId"]}/>
                <Item itemType="group" colCount={1} colSpan={2} items={["tenantId"]}/>
                <Item itemType="tabbed" colCount={1} colSpan={2} tabs={[{title: "Audit Info", colCount: 2, items: ["createdBy", "createdOn", "modifiedBy", "modifiedOn"]}]}/>

            </BaseDataGrid>
        );
    }
}

function getUserTenantsDataSource(userId) {
    return {
        store: accountServiceContext.userTenants,
        filter: ['userId', '=', userId],
        expand: 'user',
        sort: 'tenant.companyName'
    };
}

